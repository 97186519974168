import { useTranslation } from "react-i18next";
import { FormFieldType } from "../../Form";
import { ColumnProps } from "antd/es/table";
import moment from "moment";
import { Check, Close, Palette } from "@mui/icons-material";
import { SelectOption } from "../../../utils/types";
import { getPKfromSelf, isPrivileged, loadAllStaffUsers, loadCountries } from "../../../utils/functions";
import { Chip, Grid, Stack } from "@mui/material";
import { Offer, PriceObject, Request } from "./types";
import { useUserContext } from "../../../utils/context";
import Button from "../../../components/Button";

// Thumbnail Images for hardcoded demo data
// import ClipTerminalSternleiterThumbnail from '../../../assets/demoThumbnails/Clip_Terminal_Sternleiter__thumbnail.png';
// import ClipTerminalSternleiterBarThumbnail from '../../../assets/demoThumbnails/Clip_Terminal_Sternleiter_bar_thumbnail.png';
// import ClipTerminalUVThumbnail from '../../../assets/demoThumbnails/Clip_Terminal_UV_thumbnail.png';
// import PaletteTerminalSternleiterThumbnail from '../../../assets/demoThumbnails/Palette_Terminal_Sternleiter__thumbnail.png';
// import PaletteTerminalUVThumbnail from '../../../assets/demoThumbnails/Palette_Terminal_UV__thumbnail.png';
import ClipTerminalWThumbnail from '../../../assets/demoThumbnails/Clip_Terminal_W__thumbnail.png';
import PaletteTerminalWThumbnail from '../../../assets/demoThumbnails/Palette_Terminal_W__thumbnail.png';
import PaletteThumbnail from '../../../assets/demoThumbnails/Palette_thumbnail.png';
import PalettenBlechThumbnail from '../../../assets/demoThumbnails/Paletten_Blech__thumbnail.png';
import PalettenBolzenThumbnail from '../../../assets/demoThumbnails/Paletten_Bolzen__thumbnail.png';
import Zylinderstift35Thumbnail from '../../../assets/demoThumbnails/Zylinderstift_35_thumbnail.png';
import Thumbnail_Boden2 from '../../../assets/demoThumbnails/Thumbnail_Boden2.jpg';
import Thumbnail_Deckel2 from '../../../assets/demoThumbnails/Thumbnail_Deckel2.jpg';


type Props = {
    name: 'requestTableColumns'
    | 'offerTableColumns'
    | 'certificateOptions'
    | 'requestFormFields'
    | 'requestFormFieldsDisabled'
    | 'requestFormFieldsStaff'
    | 'offerFormFields'
    | 'offerFormFieldsDisabled'
    | 'offerFormFieldsStaff'
    | 'addressFormFields'
} & (
    RequestTableKwargs
    | OfferTableKwargs
    | CertificateOptionsKwargs
    | RequestFormFieldsKwargs
    | RequestFormFieldsDisabledKwargs
    | RequestFormFieldsStaffKwargs
    | OfferFormFieldsKwargs
    | OfferFormFieldsDisabledKwargs
    | OfferFormFieldsStaffKwargs
    | AddressFormFieldsKwargs
)

type RequestTableKwargs = {
    name: 'requestTableColumns'
    newRequests?: Request[]
    handleOffer?: (e: any, record: any) => void
    handleDecline?: (e: any, record: any) => void
}

type OfferTableKwargs = {
    name: 'offerTableColumns',
    newOffers?: Offer[]
    handleContract?: (e: any, record: any) => void
    handleDecline?: (e: any, record: any) => void
}

type CertificateOptionsKwargs = {
    name: 'certificateOptions'
}

type RequestFormFieldsKwargs = {
    name: 'requestFormFields'
    certificateOptions: SelectOption[]
    selectedProduct: string
}

type RequestFormFieldsDisabledKwargs = {
    name: 'requestFormFieldsDisabled'
}

type RequestFormFieldsStaffKwargs = {
    name: 'requestFormFieldsStaff'
}

type OfferFormFieldsKwargs = {
    name: 'offerFormFields'
}

type OfferFormFieldsDisabledKwargs = {
    name: 'offerFormFieldsDisabled'
}

type OfferFormFieldsStaffKwargs = {
    name: 'offerFormFieldsStaff'
}

type AddressFormFieldsKwargs = {
    name: 'addressFormFields'
}

interface RequestDetail {
    key: React.Key;
    name: string;
    article: string;
    singleQuantity: number;
    process: string;
    supplier: string;
    investmentCosts: string;
    unitPrice1: string;
    bomPrice1: string;
    total1: string;
    unitPrice2: string;
    bomPrice2: string;
    total2: string;
    children?: RequestDetail[];
}

interface BOMDetail {
    key: React.Key;
    name: string;
    article: string;
    thumbnail: string;
    process: string;
    quantity: number;
    stlViewer: string;
    children?: BOMDetail[];
}

export const hardCodedBOMTable1 : BOMDetail[] = [
    {
        key: 1,
        name: "Gesamtpalette Terminal W",
        article: "Wieland_TerminalW_163266",
        thumbnail: PaletteTerminalWThumbnail,
        process: "Assembly",
        quantity: 1,
        stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/383/files/",
        children: [
            {
                key: 2,
                name: "Grundpalette",
                article: "Wieland_163221",
                thumbnail: PaletteThumbnail,
                process: "Welding",
                quantity: 1,
                stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/385/files/",
                children: [
                    {
                        key: 3,
                        name: "Grundpaletten-Blech",
                        article: "Wieland_163220",
                        thumbnail: PalettenBlechThumbnail,
                        process: "Sheet Metal - Bending + Laser",
                        quantity: 1,
                        stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/384/files/"
                    },
                    {
                        key: 4,
                        name: "Bolzen15_002",
                        article: "Wieland_163219",
                        thumbnail: PalettenBolzenThumbnail,
                        process: "Turning",
                        quantity: 4,
                        stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/386/files/"
                    }
                ]
            },
            {
                key: 5,
                name: "Clip_Terminal-W",
                article: "Wieland_163451",
                thumbnail: ClipTerminalWThumbnail,
                process: "Injection Molding",
                quantity: 8,
                stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/388/files/"
            },
            {
                key: 6,
                name: "Zylinderstift ISO 2338 m6 A2 Edelstahl 8x35mm",
                article: "Wuerth_45120612",
                thumbnail: Zylinderstift35Thumbnail,
                process: "Purchased Part",
                quantity: 4,
                stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/387/files/"
            },
            {
                key: 7,
                name: "Plastikschraube D3 Länge 12mm",
                article: "Wuerth_62300125",
                thumbnail: "",
                process: "Purchased Part",
                quantity: 80,
                stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/389/files/"
            }
        ]
    },
]
export const hardCodedBOMTable2 : BOMDetail[] = [
    {
        key: 1,
        name: "AS_1",
        article: "AS_1 (100)",
        thumbnail: '',
        process: "Assembly",
        quantity: 1,
        stlViewer: "",
        children: [
            {
                key: 5,
                name: "Boden1",
                article: "Boden1 (102)",
                thumbnail: '',
                process: "Injection Moulding",
                quantity: 1,
                stlViewer: '',
            },
            {
                key: 2,
                name: "Boden2",
                article: "Boden2 (103)",
                thumbnail: Thumbnail_Boden2,
                process: "Milling",
                quantity: 1,
                stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/413/files/",
            },
            {
                key: 3,
                name: "Deckel2",
                article: "Deckel2 (104)",
                thumbnail: Thumbnail_Deckel2,
                process: "Milling",
                quantity: 1,
                stlViewer: "https://api.atlas.assemblean.com/v1/items/articles/414/files/"
            },
            {
                key: 4,
                name: "PCB",
                article: "PCB (101)",
                thumbnail: '',
                process: "PCB",
                quantity: 4,
                stlViewer: ''
            },
        ]
    },
]

export const hardCodedTable1: RequestDetail[] = [
    {
      key: 0,
      name: "Gesamtpalette Terminal W",
      article: "Wieland_TerminalW_163266",
      singleQuantity: 1,
      process: "Assembly",
      supplier: "***",
      investmentCosts: "",
      unitPrice1: "",
      bomPrice1: "232.16 €",
      total1: "2321.56 €",
      unitPrice2: "0.0 €",
      bomPrice2: "80.48 €",
      total2: "17852.70 €",
      children: [
        {
          key: 1,
          name: "Gesamtpalette Terminal W",
          article: "Wieland_TerminalW_163267",
          singleQuantity: 1,
          process: "Assembly",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "",
          bomPrice1: "212.41 €",
          total1: "2124.08 €",
          unitPrice2: "0.0 €",
          bomPrice2: "116.83 €",
          total2: "24093.09 €"
        },
      ]
    },
    {
      key: 2,
      name: "Grundpalette",
      article: "Wieland_163221",
      singleQuantity: 1,
      process: "Welding",
      supplier: "***",
      investmentCosts: "",
      unitPrice1: "6.28 €",
      bomPrice1: "6.28 €",
      total1: "62.8 €",
      unitPrice2: "1.89 €",
      bomPrice2: "1.89 €",
      total2: "340.2 €",
      children: [
        {
          key: 3,
          name: "Grundpalette",
          article: "Wieland_163222",
          singleQuantity: 1,
          process: "Welding",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "6.51 €",
          bomPrice1: "6.51 €",
          total1: "65.1 €",
          unitPrice2: "2.49 €",
          bomPrice2: "2.49 €",
          total2: "448.2 €"
        },
        {
          key: 4,
          name: "Grundpalette",
          article: "Wieland_163223",
          singleQuantity: 1,
          process: "Welding",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "5.23 €",
          bomPrice1: "5.23 €",
          total1: "52.3 €",
          unitPrice2: "2.77 €",
          bomPrice2: "2.77 €",
          total2: "498.6 €"
        },
        {
          key: 5,
          name: "Grundpalette",
          article: "Wieland_163224",
          singleQuantity: 1,
          process: "Welding",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "3.29 €",
          bomPrice1: "3.29 €",
          total1: "32.9 €",
          unitPrice2: "3.18 €",
          bomPrice2: "3.18 €",
          total2: "572.4 €"
        },
        {
          key: 6,
          name: "Grundpalette",
          article: "Wieland_163225",
          singleQuantity: 1,
          process: "Welding",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.13 €",
          bomPrice1: "5.13 €",
          total1: "51.3 €",
          unitPrice2: "3.23 €",
          bomPrice2: "3.23 €",
          total2: "581.4 €"
        },
      ]
    },
    {
      key: 7,
      name: "Grundpaletten-Blech",
      article: "Wieland_163220",
      singleQuantity: 1,
      process: "Sheet Metal - Bending + Laser",
      supplier: "***",
      investmentCosts: "",
      unitPrice1: "78.36 €",
      bomPrice1: "78.36 €",
      total1: "783.6 €",
      unitPrice2: "28.92 €",
      bomPrice2: "28.92 €",
      total2: "5205.6 €",
      children: [
        {
          key: 8,
          name: "Grundpaletten-Blech",
          article: "Wieland_163221",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "95.12 €",
          bomPrice1: "95.12 €",
          total1: "951.2 €",
          unitPrice2: "31.53 €",
          bomPrice2: "31.53 €",
          total2: "5675.4 €"
        },
        {
          key: 9,
          name: "Grundpaletten-Blech",
          article: "Wieland_163222",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "106.95 €",
          bomPrice1: "106.95 €",
          total1: "1069.5 €",
          unitPrice2: "32.65 €",
          bomPrice2: "32.65 €",
          total2: "5877.0 €"
        },
        {
          key: 10,
          name: "Grundpaletten-Blech",
          article: "Wieland_163223",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "110.72 €",
          bomPrice1: "110.72 €",
          total1: "1107.2 €",
          unitPrice2: "32.89 €",
          bomPrice2: "32.89 €",
          total2: "5920.2 €"
        },
        {
          key: 11,
          name: "Grundpaletten-Blech",
          article: "Wieland_163224",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "91.76 €",
          bomPrice1: "91.76 €",
          total1: "917.6 €",
          unitPrice2: "33.82 €",
          bomPrice2: "33.82 €",
          total2: "6087.6 €"
        },
        {
          key: 12,
          name: "Grundpaletten-Blech",
          article: "Wieland_163225",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "73.18 €",
          bomPrice1: "73.18 €",
          total1: "731.8 €",
          unitPrice2: "34.82 €",
          bomPrice2: "34.82 €",
          total2: "6267.6 €"
        },
        {
          key: 13,
          name: "Grundpaletten-Blech",
          article: "Wieland_163226",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "106.27 €",
          bomPrice1: "106.27 €",
          total1: "1062.7 €",
          unitPrice2: "38.86 €",
          bomPrice2: "38.86 €",
          total2: "6994.8 €"
        },
        {
          key: 14,
          name: "Grundpaletten-Blech",
          article: "Wieland_163227",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "87.72 €",
          bomPrice1: "87.72 €",
          total1: "877.2 €",
          unitPrice2: "39.12 €",
          bomPrice2: "39.12 €",
          total2: "7041.6 €"
        },
        {
          key: 15,
          name: "Grundpaletten-Blech",
          article: "Wieland_163228",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "96.86 €",
          bomPrice1: "96.86 €",
          total1: "968.6 €",
          unitPrice2: "42.69 €",
          bomPrice2: "42.69 €",
          total2: "7684.2 €"
        },
        {
          key: 16,
          name: "Grundpaletten-Blech",
          article: "Wieland_163229",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Bunching",
          supplier: "calculated",
          investmentCosts: "3581.67 €",
          unitPrice1: "74.25 €",
          bomPrice1: "74.25 €",
          total1: "742.5 €",
          unitPrice2: "44.83 €",
          bomPrice2: "44.83 €",
          total2: "8069.4 €"
        },
        {
          key: 17,
          name: "Grundpaletten-Blech",
          article: "Wieland_163230",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "110.68 €",
          bomPrice1: "110.68 €",
          total1: "1106.8 €",
          unitPrice2: "44.94 €",
          bomPrice2: "44.94 €",
          total2: "8089.2 €"
        },
        {
          key: 18,
          name: "Grundpaletten-Blech",
          article: "Wieland_163231",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "91.78 €",
          bomPrice1: "91.78 €",
          total1: "917.8 €",
          unitPrice2: "46.86 €",
          bomPrice2: "46.86 €",
          total2: "8434.8 €"
        },
        {
          key: 19,
          name: "Grundpaletten-Blech",
          article: "Wieland_163232",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "93.91 €",
          bomPrice1: "93.91 €",
          total1: "939.1 €",
          unitPrice2: "47.44 €",
          bomPrice2: "47.44 €",
          total2: "8539.2 €"
        },
        {
          key: 20,
          name: "Grundpaletten-Blech",
          article: "Wieland_163233",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "93.38 €",
          bomPrice1: "93.38 €",
          total1: "933.8 €",
          unitPrice2: "49.04 €",
          bomPrice2: "49.04 €",
          total2: "8827.2 €"
        },
        {
          key: 21,
          name: "Grundpaletten-Blech",
          article: "Wieland_163234",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "99.62 €",
          bomPrice1: "99.62 €",
          total1: "996.2 €",
          unitPrice2: "53.42 €",
          bomPrice2: "53.42 €",
          total2: "9615.6 €"
        },
        {
          key: 22,
          name: "Grundpaletten-Blech",
          article: "Wieland_163235",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "100.16 €",
          bomPrice1: "100.16 €",
          total1: "1001.6 €",
          unitPrice2: "55.06 €",
          bomPrice2: "55.06 €",
          total2: "9910.8 €"
        },
        {
          key: 23,
          name: "Grundpaletten-Blech",
          article: "Wieland_163236",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "109.22 €",
          bomPrice1: "109.22 €",
          total1: "1092.2 €",
          unitPrice2: "55.3 €",
          bomPrice2: "55.3 €",
          total2: "9954.0 €"
        },
        {
          key: 24,
          name: "Grundpaletten-Blech",
          article: "Wieland_163237",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Laser",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "81.55 €",
          bomPrice1: "81.55 €",
          total1: "815.5 €",
          unitPrice2: "55.84 €",
          bomPrice2: "55.84 €",
          total2: "10051.2 €"
        },
        {
          key: 25,
          name: "Grundpaletten-Blech",
          article: "Wieland_163238",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Stamping/Nibbling",
          supplier: "calculated",
          investmentCosts: "1152.61 €",
          unitPrice1: "75.59 €",
          bomPrice1: "75.59 €",
          total1: "755.9 €",
          unitPrice2: "52.27 €",
          bomPrice2: "52.27 €",
          total2: "10561.21 €"
        },
        {
          key: 26,
          name: "Grundpaletten-Blech",
          article: "Wieland_163239",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Water jet cutting",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "117.55 €",
          bomPrice1: "117.55 €",
          total1: "1175.5 €",
          unitPrice2: "77.83 €",
          bomPrice2: "77.83 €",
          total2: "14009.4 €"
        },
        {
          key: 27,
          name: "Grundpaletten-Blech",
          article: "Wieland_163240",
          singleQuantity: 1,
          process: "Sheet Metal - Bending + Plasma",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "168.49 €",
          bomPrice1: "168.49 €",
          total1: "1684.9 €",
          unitPrice2: "88.92 €",
          bomPrice2: "88.92 €",
          total2: "16005.6 €"
        },
      ]
    },
    {
      key: 28,
      name: "Bolzen15_002",
      article: "Wieland_163219",
      singleQuantity: 4,
      process: "Turning",
      supplier: "***",
      investmentCosts: "",
      unitPrice1: "3.68 €",
      bomPrice1: "14.72 €",
      total1: "147.2 €",
      unitPrice2: "1.51 €",
      bomPrice2: "6.04 €",
      total2: "1087.2 €",
      children: [
        {
          key: 29,
          name: "Bolzen15_002",
          article: "Wieland_163220",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.14 €",
          bomPrice1: "16.56 €",
          total1: "165.6 €",
          unitPrice2: "2.02 €",
          bomPrice2: "8.08 €",
          total2: "1454.4 €"
        },
        {
          key: 30,
          name: "Bolzen15_002",
          article: "Wieland_163221",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.03 €",
          bomPrice1: "20.12 €",
          total1: "201.2 €",
          unitPrice2: "2.07 €",
          bomPrice2: "8.28 €",
          total2: "1490.4 €"
        },
        {
          key: 31,
          name: "Bolzen15_002",
          article: "Wieland_163222",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.58 €",
          bomPrice1: "18.32 €",
          total1: "183.2 €",
          unitPrice2: "2.07 €",
          bomPrice2: "8.28 €",
          total2: "1490.4 €"
        },
        {
          key: 32,
          name: "Bolzen15_002",
          article: "Wieland_163223",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.75 €",
          bomPrice1: "19.0 €",
          total1: "190.0 €",
          unitPrice2: "2.11 €",
          bomPrice2: "8.44 €",
          total2: "1519.2 €"
        },
        {
          key: 33,
          name: "Bolzen15_002",
          article: "Wieland_163224",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.53 €",
          bomPrice1: "18.12 €",
          total1: "181.2 €",
          unitPrice2: "2.22 €",
          bomPrice2: "8.88 €",
          total2: "1598.4 €"
        },
        {
          key: 34,
          name: "Bolzen15_002",
          article: "Wieland_163225",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.34 €",
          bomPrice1: "17.36 €",
          total1: "173.6 €",
          unitPrice2: "2.26 €",
          bomPrice2: "9.04 €",
          total2: "1627.2 €"
        },
        {
          key: 35,
          name: "Bolzen15_002",
          article: "Wieland_163226",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.59 €",
          bomPrice1: "22.36 €",
          total1: "223.6 €",
          unitPrice2: "2.36 €",
          bomPrice2: "9.44 €",
          total2: "1699.2 €"
        },
        {
          key: 36,
          name: "Bolzen15_002",
          article: "Wieland_163227",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.82 €",
          bomPrice1: "23.28 €",
          total1: "232.8 €",
          unitPrice2: "2.41 €",
          bomPrice2: "9.64 €",
          total2: "1735.2 €"
        },
        {
          key: 37,
          name: "Bolzen15_002",
          article: "Wieland_163228",
          singleQuantity: 4,
          process: "Turning",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "4.63 €",
          bomPrice1: "18.52 €",
          total1: "185.2 €",
          unitPrice2: "2.41 €",
          bomPrice2: "9.64 €",
          total2: "1735.2 €"
        },
        {
          key: 38,
          name: "Bolzen15_002",
          article: "Wieland_163229",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.4 €",
          bomPrice1: "21.6 €",
          total1: "216.0 €",
          unitPrice2: "2.42 €",
          bomPrice2: "9.68 €",
          total2: "1742.4 €"
        },
        {
          key: 39,
          name: "Bolzen15_002",
          article: "Wieland_163230",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.72 €",
          bomPrice1: "18.88 €",
          total1: "188.8 €",
          unitPrice2: "2.55 €",
          bomPrice2: "10.2 €",
          total2: "1836.0 €"
        },
        {
          key: 40,
          name: "Bolzen15_002",
          article: "Wieland_163231",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.73 €",
          bomPrice1: "18.92 €",
          total1: "189.2 €",
          unitPrice2: "2.56 €",
          bomPrice2: "10.24 €",
          total2: "1843.2 €"
        },
        {
          key: 41,
          name: "Bolzen15_002",
          article: "Wieland_163232",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.28 €",
          bomPrice1: "21.12 €",
          total1: "211.2 €",
          unitPrice2: "2.58 €",
          bomPrice2: "10.32 €",
          total2: "1857.6 €"
        },
        {
          key: 42,
          name: "Bolzen15_002",
          article: "Wieland_163233",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.13 €",
          bomPrice1: "20.52 €",
          total1: "205.2 €",
          unitPrice2: "2.6 €",
          bomPrice2: "10.4 €",
          total2: "1872.0 €"
        },
        {
          key: 43,
          name: "Bolzen15_002",
          article: "Wieland_163234",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.55 €",
          bomPrice1: "22.2 €",
          total1: "222.0 €",
          unitPrice2: "2.61 €",
          bomPrice2: "10.44 €",
          total2: "1879.2 €"
        },
        {
          key: 44,
          name: "Bolzen15_002",
          article: "Wieland_163235",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.72 €",
          bomPrice1: "18.88 €",
          total1: "188.8 €",
          unitPrice2: "2.7 €",
          bomPrice2: "10.8 €",
          total2: "1944.0 €"
        },
        {
          key: 45,
          name: "Bolzen15_002",
          article: "Wieland_163236",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.52 €",
          bomPrice1: "22.08 €",
          total1: "220.8 €",
          unitPrice2: "2.82 €",
          bomPrice2: "11.28 €",
          total2: "2030.4 €"
        },
        {
          key: 46,
          name: "Bolzen15_002",
          article: "Wieland_163237",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.37 €",
          bomPrice1: "21.48 €",
          total1: "214.8 €",
          unitPrice2: "2.87 €",
          bomPrice2: "11.48 €",
          total2: "2066.4 €"
        },
        {
          key: 47,
          name: "Bolzen15_002",
          article: "Wieland_163238",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.61 €",
          bomPrice1: "22.44 €",
          total1: "224.4 €",
          unitPrice2: "2.9 €",
          bomPrice2: "11.6 €",
          total2: "2088.0 €"
        },
        {
          key: 48,
          name: "Bolzen15_002",
          article: "Wieland_163239",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.88 €",
          bomPrice1: "23.52 €",
          total1: "235.2 €",
          unitPrice2: "2.9 €",
          bomPrice2: "11.6 €",
          total2: "2088.0 €"
        },
        {
          key: 49,
          name: "Bolzen15_002",
          article: "Wieland_163240",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "4.59 €",
          bomPrice1: "18.36 €",
          total1: "183.6 €",
          unitPrice2: "2.92 €",
          bomPrice2: "11.68 €",
          total2: "2102.4 €"
        },
        {
          key: 50,
          name: "Bolzen15_002",
          article: "Wieland_163241",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.33 €",
          bomPrice1: "21.32 €",
          total1: "213.2 €",
          unitPrice2: "2.92 €",
          bomPrice2: "11.68 €",
          total2: "2102.4 €"
        },
        {
          key: 51,
          name: "Bolzen15_002",
          article: "Wieland_163242",
          singleQuantity: 4,
          process: "Turning",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "5.67 €",
          bomPrice1: "22.68 €",
          total1: "226.8 €",
          unitPrice2: "2.99 €",
          bomPrice2: "11.96 €",
          total2: "2152.8 €"
        },
        {
          key: 52,
          name: "Bolzen15_002",
          article: "Wieland_163243",
          singleQuantity: 4,
          process: "Milling",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "13.64 €",
          bomPrice1: "54.56 €",
          total1: "545.6 €",
          unitPrice2: "5.93 €",
          bomPrice2: "23.72 €",
          total2: "4269.6 €"
        },
        {
          key: 53,
          name: "Bolzen15_002",
          article: "Wieland_163244",
          singleQuantity: 4,
          process: "Sand casting + milling",
          supplier: "calculated",
          investmentCosts: "2150.61 €",
          unitPrice1: "9.01 €",
          bomPrice1: "36.04 €",
          total1: "360.4 €",
          unitPrice2: "4.31 €",
          bomPrice2: "17.24 €",
          total2: "5253.81 €"
        },
        {
          key: 54,
          name: "Bolzen15_002",
          article: "Wieland_163245",
          singleQuantity: 4,
          process: "Sand casting + turning",
          supplier: "calculated",
          investmentCosts: "2150.61 €",
          unitPrice1: "11.26 €",
          bomPrice1: "45.04 €",
          total1: "450.4 €",
          unitPrice2: "4.53 €",
          bomPrice2: "18.12 €",
          total2: "5412.21 €"
        },
        {
          key: 55,
          name: "Bolzen15_002",
          article: "Wieland_163246",
          singleQuantity: 4,
          process: "Die casting + turning",
          supplier: "calculated",
          investmentCosts: "5298.25 €",
          unitPrice1: "3.19 €",
          bomPrice1: "12.76 €",
          total1: "127.6 €",
          unitPrice2: "1.64 €",
          bomPrice2: "6.56 €",
          total2: "6479.05 €"
        },
        {
          key: 56,
          name: "Bolzen15_002",
          article: "Wieland_163247",
          singleQuantity: 4,
          process: "Die casting + milling",
          supplier: "calculated",
          investmentCosts: "5298.25 €",
          unitPrice1: "4.15 €",
          bomPrice1: "16.6 €",
          total1: "166.0 €",
          unitPrice2: "2.19 €",
          bomPrice2: "8.76 €",
          total2: "6875.05 €"
        },
      ]
    },
    {
      key: 57,
      name: "Clip_Terminal-W",
      article: "Wieland_163451",
      singleQuantity: 8,
      process: "Injection Moulding",
      supplier: "***",
      investmentCosts: "2650.64 €",
      unitPrice1: "9.69 €",
      bomPrice1: "77.52 €",
      total1: "775.2 €",
      unitPrice2: "2.67 €",
      bomPrice2: "21.36 €",
      total2: "6495.44 €",
      children: [
        {
          key: 58,
          name: "Clip_Terminal-W",
          article: "Wieland_163452",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "3184.71 €",
          unitPrice1: "9.8 €",
          bomPrice1: "78.4 €",
          total1: "784.0 €",
          unitPrice2: "2.44 €",
          bomPrice2: "19.52 €",
          total2: "6698.31 €"
        },
        {
          key: 59,
          name: "Clip_Terminal-W",
          article: "Wieland_163453",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "3531.79 €",
          unitPrice1: "10.2 €",
          bomPrice1: "81.6 €",
          total1: "816.0 €",
          unitPrice2: "2.69 €",
          bomPrice2: "21.52 €",
          total2: "7405.39 €"
        },
        {
          key: 60,
          name: "Clip_Terminal-W",
          article: "Wieland_163454",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "3403.71 €",
          unitPrice1: "6.23 €",
          bomPrice1: "49.84 €",
          total1: "498.4 €",
          unitPrice2: "3.19 €",
          bomPrice2: "25.52 €",
          total2: "7997.31 €"
        },
        {
          key: 61,
          name: "Clip_Terminal-W",
          article: "Wieland_163455",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "3975.36 €",
          unitPrice1: "9.97 €",
          bomPrice1: "79.76 €",
          total1: "797.6 €",
          unitPrice2: "2.85 €",
          bomPrice2: "22.8 €",
          total2: "8079.36 €"
        },
        {
          key: 62,
          name: "Clip_Terminal-W",
          article: "Wieland_163456",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "2864.64 €",
          unitPrice1: "5.39 €",
          bomPrice1: "43.12 €",
          total1: "431.2 €",
          unitPrice2: "3.74 €",
          bomPrice2: "29.92 €",
          total2: "8250.24 €"
        },
        {
          key: 63,
          name: "Clip_Terminal-W",
          article: "Wieland_163457",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "3385.54 €",
          unitPrice1: "8.14 €",
          bomPrice1: "65.12 €",
          total1: "651.2 €",
          unitPrice2: "3.58 €",
          bomPrice2: "28.64 €",
          total2: "8540.74 €"
        },
        {
          key: 64,
          name: "Clip_Terminal-W",
          article: "Wieland_163458",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "4390.26 €",
          unitPrice1: "6.26 €",
          bomPrice1: "50.08 €",
          total1: "500.8 €",
          unitPrice2: "3.1 €",
          bomPrice2: "24.8 €",
          total2: "8854.26 €"
        },
        {
          key: 65,
          name: "Clip_Terminal-W",
          article: "Wieland_163459",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "3065.71 €",
          unitPrice1: "10.01 €",
          bomPrice1: "80.08 €",
          total1: "800.8 €",
          unitPrice2: "4.36 €",
          bomPrice2: "34.88 €",
          total2: "9344.11 €"
        },
        {
          key: 66,
          name: "Clip_Terminal-W",
          article: "Wieland_163460",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "3461.92 €",
          unitPrice1: "6.03 €",
          bomPrice1: "48.24 €",
          total1: "482.4 €",
          unitPrice2: "4.22 €",
          bomPrice2: "33.76 €",
          total2: "9538.72 €"
        },
        {
          key: 67,
          name: "Clip_Terminal-W",
          article: "Wieland_163461",
          singleQuantity: 8,
          process: "Injection Moulding 1",
          supplier: "calculated",
          investmentCosts: "2412.74 €",
          unitPrice1: "8.05 €",
          bomPrice1: "64.4 €",
          total1: "644.0 €",
          unitPrice2: "4.95 €",
          bomPrice2: "39.6 €",
          total2: "9540.74 €"
        },
        {
          key: 68,
          name: "Clip_Terminal-W",
          article: "Wieland_163462",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "2660.36 €",
          unitPrice1: "10.19 €",
          bomPrice1: "81.52 €",
          total1: "815.2 €",
          unitPrice2: "4.87 €",
          bomPrice2: "38.96 €",
          total2: "9673.16 €"
        },
        {
          key: 69,
          name: "Clip_Terminal-W",
          article: "Wieland_163463",
          singleQuantity: 8,
          process: "Injection Moulding 3",
          supplier: "calculated",
          investmentCosts: "1946.38 €",
          unitPrice1: "11.37 €",
          bomPrice1: "90.96 €",
          total1: "909.6 €",
          unitPrice2: "5.63 €",
          bomPrice2: "45.04 €",
          total2: "10053.58 €"
        },
        {
          key: 70,
          name: "Clip_Terminal-W",
          article: "Wieland_163464",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "2670.1 €",
          unitPrice1: "5.63 €",
          bomPrice1: "45.04 €",
          total1: "450.4 €",
          unitPrice2: "5.18 €",
          bomPrice2: "41.44 €",
          total2: "10129.3 €"
        },
        {
          key: 71,
          name: "Clip_Terminal-W",
          article: "Wieland_163465",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "2784.19 €",
          unitPrice1: "5.29 €",
          bomPrice1: "42.32 €",
          total1: "423.2 €",
          unitPrice2: "5.24 €",
          bomPrice2: "41.92 €",
          total2: "10329.79 €"
        },
        {
          key: 72,
          name: "Clip_Terminal-W",
          article: "Wieland_163466",
          singleQuantity: 8,
          process: "Injection Moulding 2",
          supplier: "calculated",
          investmentCosts: "1866.22 €",
          unitPrice1: "8.49 €",
          bomPrice1: "67.92 €",
          total1: "679.2 €",
          unitPrice2: "5.97 €",
          bomPrice2: "47.76 €",
          total2: "10463.02 €"
        },
        {
          key: 73,
          name: "Clip_Terminal-W",
          article: "Wieland_163467",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "4234.42 €",
          unitPrice1: "8.91 €",
          bomPrice1: "71.28 €",
          total1: "712.8 €",
          unitPrice2: "4.34 €",
          bomPrice2: "34.72 €",
          total2: "10484.02 €"
        },
        {
          key: 74,
          name: "Clip_Terminal-W",
          article: "Wieland_163468",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "5751.47 €",
          unitPrice1: "7.45 €",
          bomPrice1: "59.6 €",
          total1: "596.0 €",
          unitPrice2: "3.37 €",
          bomPrice2: "26.96 €",
          total2: "10604.27 €"
        },
        {
          key: 75,
          name: "Clip_Terminal-W",
          article: "Wieland_163469",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "6053.94 €",
          unitPrice1: "6.8 €",
          bomPrice1: "54.4 €",
          total1: "544.0 €",
          unitPrice2: "3.21 €",
          bomPrice2: "25.68 €",
          total2: "10676.34 €"
        },
        {
          key: 76,
          name: "Clip_Terminal-W",
          article: "Wieland_163470",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "4223.76 €",
          unitPrice1: "7.21 €",
          bomPrice1: "57.68 €",
          total1: "576.8 €",
          unitPrice2: "4.75 €",
          bomPrice2: "38.0 €",
          total2: "11063.76 €"
        },
        {
          key: 77,
          name: "Clip_Terminal-W",
          article: "Wieland_163471",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "5101.34 €",
          unitPrice1: "7.87 €",
          bomPrice1: "62.96 €",
          total1: "629.6 €",
          unitPrice2: "4.16 €",
          bomPrice2: "33.28 €",
          total2: "11091.74 €"
        },
        {
          key: 78,
          name: "Clip_Terminal-W",
          article: "Wieland_163472",
          singleQuantity: 8,
          process: "Deep drawing",
          supplier: "calculated",
          investmentCosts: "6617.99 €",
          unitPrice1: "10.14 €",
          bomPrice1: "81.12 €",
          total1: "811.2 €",
          unitPrice2: "3.44 €",
          bomPrice2: "27.52 €",
          total2: "11571.59 €"
        },
        {
          key: 79,
          name: "Clip_Terminal-W",
          article: "Wieland_163473",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "6324.1 €",
          unitPrice1: "6.98 €",
          bomPrice1: "55.84 €",
          total1: "558.4 €",
          unitPrice2: "3.92 €",
          bomPrice2: "31.36 €",
          total2: "11968.9 €"
        },
        {
          key: 80,
          name: "Clip_Terminal-W",
          article: "Wieland_163474",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "6105.5 €",
          unitPrice1: "9.44 €",
          bomPrice1: "75.52 €",
          total1: "755.2 €",
          unitPrice2: "4.14 €",
          bomPrice2: "33.12 €",
          total2: "12067.1 €"
        },
        {
          key: 81,
          name: "Clip_Terminal-W",
          article: "Wieland_163475",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "5895.4 €",
          unitPrice1: "5.9 €",
          bomPrice1: "47.2 €",
          total1: "472.0 €",
          unitPrice2: "4.55 €",
          bomPrice2: "36.4 €",
          total2: "12447.4 €"
        },
        {
          key: 82,
          name: "Clip_Terminal-W",
          article: "Wieland_163476",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "5632.85 €",
          unitPrice1: "6.08 €",
          bomPrice1: "48.64 €",
          total1: "486.4 €",
          unitPrice2: "5.3 €",
          bomPrice2: "42.4 €",
          total2: "13264.85 €"
        },
        {
          key: 83,
          name: "Clip_Terminal-W",
          article: "Wieland_163477",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "6417.25 €",
          unitPrice1: "10.5 €",
          bomPrice1: "84.0 €",
          total1: "840.0 €",
          unitPrice2: "5.13 €",
          bomPrice2: "41.04 €",
          total2: "13804.45 €"
        },
        {
          key: 84,
          name: "Clip_Terminal-W",
          article: "Wieland_163478",
          singleQuantity: 8,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "7120.78 €",
          unitPrice1: "10.05 €",
          bomPrice1: "80.4 €",
          total1: "804.0 €",
          unitPrice2: "5.2 €",
          bomPrice2: "41.6 €",
          total2: "14608.78 €"
        },
        {
          key: 85,
          name: "Clip_Terminal-W",
          article: "Wieland_163479",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "447.16 €",
          unitPrice1: "30.62 €",
          bomPrice1: "244.96 €",
          total1: "2449.6 €",
          unitPrice2: "10.69 €",
          bomPrice2: "85.52 €",
          total2: "15840.76 €"
        },
        {
          key: 86,
          name: "Clip_Terminal-W",
          article: "Wieland_163480",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "calculated",
          investmentCosts: "569.62 €",
          unitPrice1: "37.73 €",
          bomPrice1: "301.84 €",
          total1: "3018.4 €",
          unitPrice2: "11.01 €",
          bomPrice2: "88.08 €",
          total2: "16424.02 €"
        },
        {
          key: 87,
          name: "Clip_Terminal-W",
          article: "Wieland_163481",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "211.95 €",
          unitPrice1: "40.18 €",
          bomPrice1: "321.44 €",
          total1: "3214.4 €",
          unitPrice2: "11.34 €",
          bomPrice2: "90.72 €",
          total2: "16541.55 €"
        },
        {
          key: 88,
          name: "Clip_Terminal-W",
          article: "Wieland_163482",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "506.46 €",
          unitPrice1: "41.47 €",
          bomPrice1: "331.76 €",
          total1: "3317.6 €",
          unitPrice2: "12.2 €",
          bomPrice2: "97.6 €",
          total2: "18074.46 €"
        },
        {
          key: 89,
          name: "Clip_Terminal-W",
          article: "Wieland_163483",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "234.14 €",
          unitPrice1: "20.99 €",
          bomPrice1: "167.92 €",
          total1: "1679.2 €",
          unitPrice2: "13.48 €",
          bomPrice2: "107.84 €",
          total2: "19645.34 €"
        },
        {
          key: 90,
          name: "Clip_Terminal-W",
          article: "Wieland_163484",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "282.25 €",
          unitPrice1: "38.78 €",
          bomPrice1: "310.24 €",
          total1: "3102.4 €",
          unitPrice2: "13.59 €",
          bomPrice2: "108.72 €",
          total2: "19851.85 €"
        },
        {
          key: 91,
          name: "Clip_Terminal-W",
          article: "Wieland_163485",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "410.51 €",
          unitPrice1: "30.37 €",
          bomPrice1: "242.96 €",
          total1: "2429.6 €",
          unitPrice2: "13.69 €",
          bomPrice2: "109.52 €",
          total2: "20124.11 €"
        },
        {
          key: 92,
          name: "Clip_Terminal-W",
          article: "Wieland_163486",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "493.29 €",
          unitPrice1: "26.13 €",
          bomPrice1: "209.04 €",
          total1: "2090.4 €",
          unitPrice2: "14.59 €",
          bomPrice2: "116.72 €",
          total2: "21502.89 €"
        },
        {
          key: 93,
          name: "Clip_Terminal-W",
          article: "Wieland_163487",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "573.85 €",
          unitPrice1: "25.32 €",
          bomPrice1: "202.56 €",
          total1: "2025.6 €",
          unitPrice2: "15.92 €",
          bomPrice2: "127.36 €",
          total2: "23498.65 €"
        },
        {
          key: 94,
          name: "Clip_Terminal-W",
          article: "Wieland_163488",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "306.16 €",
          unitPrice1: "22.43 €",
          bomPrice1: "179.44 €",
          total1: "1794.4 €",
          unitPrice2: "16.54 €",
          bomPrice2: "132.32 €",
          total2: "24123.76 €"
        },
        {
          key: 95,
          name: "Clip_Terminal-W",
          article: "Wieland_163489",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "218.1 €",
          unitPrice1: "35.15 €",
          bomPrice1: "281.2 €",
          total1: "2812.0 €",
          unitPrice2: "16.69 €",
          bomPrice2: "133.52 €",
          total2: "24251.7 €"
        },
        {
          key: 96,
          name: "Clip_Terminal-W",
          article: "Wieland_163490",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "225.83 €",
          unitPrice1: "24.67 €",
          bomPrice1: "197.36 €",
          total1: "1973.6 €",
          unitPrice2: "16.71 €",
          bomPrice2: "133.68 €",
          total2: "24288.23 €"
        },
        {
          key: 97,
          name: "Clip_Terminal-W",
          article: "Wieland_163491",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "305.28 €",
          unitPrice1: "36.27 €",
          bomPrice1: "290.16 €",
          total1: "2901.6 €",
          unitPrice2: "17.24 €",
          bomPrice2: "137.92 €",
          total2: "25130.88 €"
        },
        {
          key: 98,
          name: "Clip_Terminal-W",
          article: "Wieland_163492",
          singleQuantity: 8,
          process: "3d printing - FDM",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "43.35 €",
          bomPrice1: "346.8 €",
          total1: "3468.0 €",
          unitPrice2: "19.54 €",
          bomPrice2: "156.32 €",
          total2: "28137.6 €"
        },
        {
          key: 99,
          name: "Clip_Terminal-W",
          article: "Wieland_163493",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "387.24 €",
          unitPrice1: "27.1 €",
          bomPrice1: "216.8 €",
          total1: "2168.0 €",
          unitPrice2: "19.35 €",
          bomPrice2: "154.8 €",
          total2: "28251.24 €"
        },
        {
          key: 100,
          name: "Clip_Terminal-W",
          article: "Wieland_163494",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "465.26 €",
          unitPrice1: "28.82 €",
          bomPrice1: "230.56 €",
          total1: "2305.6 €",
          unitPrice2: "20.43 €",
          bomPrice2: "163.44 €",
          total2: "29884.46 €"
        },
        {
          key: 101,
          name: "Clip_Terminal-W",
          article: "Wieland_163495",
          singleQuantity: 8,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "241.63 €",
          unitPrice1: "35.7 €",
          bomPrice1: "285.6 €",
          total1: "2856.0 €",
          unitPrice2: "21.19 €",
          bomPrice2: "169.52 €",
          total2: "30755.23 €"
        },
        {
          key: 102,
          name: "Clip_Terminal-W",
          article: "Wieland_163496",
          singleQuantity: 8,
          process: "3d printing - SLS",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "85.91 €",
          bomPrice1: "687.28 €",
          total1: "6872.8 €",
          unitPrice2: "30.48 €",
          bomPrice2: "243.84 €",
          total2: "43891.2 €"
        },
        {
          key: 103,
          name: "Clip_Terminal-W",
          article: "Wieland_163497",
          singleQuantity: 8,
          process: "3d printing - MJF",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "102.54 €",
          bomPrice1: "820.32 €",
          total1: "8203.2 €",
          unitPrice2: "72.67 €",
          bomPrice2: "581.36 €",
          total2: "104644.8 €"
        },
      ]
    },
    {
      key: 104,
      name: "Zylinderstift ISO 2338 m6 A2 Edelstahl 8x35mm",
      article: "Wuerth_45120612",
      singleQuantity: 4,
      process: "Purchased Part",
      supplier: "calculated",
      investmentCosts: "",
      unitPrice1: "0.48 €",
      bomPrice1: "1.92 €",
      total1: "19.2 €",
      unitPrice2: "0.49 €",
      bomPrice2: "1.96 €",
      total2: "352.8 €"
    },
    {
      key: 105,
      name: "Plastikschraube D3 Länge 12mm",
      article: "Wuerth_62300125",
      singleQuantity: 80,
      process: "Purchased Part",
      supplier: "calculated",
      investmentCosts: "",
      unitPrice1: "0.05 €",
      bomPrice1: "4.0 €",
      total1: "40.0 €",
      unitPrice2: "0.04 €",
      bomPrice2: "3.2 €",
      total2: "576.0 €"
    }
  ];


export const hardCodedTable2: RequestDetail[] = [
    {
      key: 0,
      name: "AS_1",
      article: "AS_1 (100)",
      singleQuantity: 1,
      process: "Assembly",
      supplier: "***",
      investmentCosts: "",
      unitPrice1: "",
      bomPrice1: "",
      total1: "",
      unitPrice2: "",
      bomPrice2: "",
      total2: "",
      children: [
        {
          key: 1,
          name: "AS_1",
          article: "AS_1 (100)",
          singleQuantity: 1,
          process: "Assembly",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "",
          bomPrice1: "",
          total1: "",
          unitPrice2: "",
          bomPrice2: "",
          total2: ""
        },
      ]
    },
    {
      key: 2,
      name: "PCB",
      article: "PCB (101)",
      singleQuantity: 1,
      process: "PCB",
      supplier: "***",
      investmentCosts: "500.00 €",
      unitPrice1: "52.17 €",
      bomPrice1: "52.17 €",
      total1: " 1021.70 €",
      unitPrice2: "9.85 €",
      bomPrice2: "9.85 €",
      total2: "10350.00 €",
      children: [
        {
          key: 3,
          name: "PCB",
          article: "PCB (101)",
          singleQuantity: 1,
          process: "PCB",
          supplier: "***",
          investmentCosts: "250.00 €",
          unitPrice1: "58.87 €",
          bomPrice1: "58.87 €",
          total1: "838.70 €",
          unitPrice2: "14.50 €",
          bomPrice2: "12.25 €",
          total2: "12500.00 €"
        },
        {
          key: 4,
          name: "PCB",
          article: "PCB (101)",
          singleQuantity: 1,
          process: "PCB",
          supplier: "***",
          investmentCosts: "700.00 €",
          unitPrice1: "72.51 €",
          bomPrice1: "72.51 €",
          total1: "1425.10 €",
          unitPrice2: "12.25 €",
          bomPrice2: "14.50 €",
          total2: "15200.00 €"
        },
        {
          key: 5,
          name: "PCB",
          article: "PCB (101)",
          singleQuantity: 1,
          process: "PCB",
          supplier: "***",
          investmentCosts: "298.54 €",
          unitPrice1: "74.15 €",
          bomPrice1: "74.15 €",
          total1: "1040.04 €",
          unitPrice2: "17.18 €",
          bomPrice2: "17.18 €",
          total2: "17478.54 €"
        },
      ]
    },
    {
      key: 7,
      name: "Boden1",
      article: "Boden1 (102)",
      singleQuantity: 1,
      process: "Injection Moulding",
      supplier: "***",
      investmentCosts: "2100.00 €",
      unitPrice1: "2.71 €",
      bomPrice1: "2.71 €",
      total1: "2127.10 €",
      unitPrice2: "0.90 €",
      bomPrice2: "0.90 €",
      total2: "3003.33 €",
      children: [
        {
          key: 8,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "1854.00 €",
          unitPrice1: "3.19 €",
          bomPrice1: "3.19 €",
          total1: "1885.90 €",
          unitPrice2: "1.24 €",
          bomPrice2: "1.24 €",
          total2: "3094.00 €"
        },
        {
          key: 9,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding 1",
          supplier: "calculated",
          investmentCosts: "2258.84 €",
          unitPrice1: "3.74 €",
          bomPrice1: "3.74 €",
          total1: "2296.24 €",
          unitPrice2: "1.25 €",
          bomPrice2: "1.25 €",
          total2: "3505.51 €"
        },
        {
          key: 10,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "1985.52 €",
          unitPrice1: "4.12 €",
          bomPrice1: "4.12 €",
          total1: "2026.72 €",
          unitPrice2: "1.59 €",
          bomPrice2: "1.59 €",
          total2: "3575.52 €"
        },
        {
          key: 11,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding",
          supplier: "calculated",
          investmentCosts: "1854.21 €",
          unitPrice1: "5.63 €",
          bomPrice1: "5.63 €",
          total1: "1910.51 €",
          unitPrice2: "1.88 €",
          bomPrice2: "1.88 €",
          total2: "3730.88 €"
        },
        {
          key: 12,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Deep drawing",
          supplier: "calculated",
          investmentCosts: "812.36 €",
          unitPrice1: "8.22 €",
          bomPrice1: "8.22 €",
          total1: "894.56 €",
          unitPrice2: "2.95 €",
          bomPrice2: "2.95 €",
          total2: "3762.36 €"
        },
        {
          key: 13,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "4258.71 €",
          unitPrice1: "6.89 €",
          bomPrice1: "6.89 €",
          total1: "4327.61 €",
          unitPrice2: "2.30 €",
          bomPrice2: "2.30 €",
          total2: "6555.38 €"
        },
        {
          key: 14,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding 2",
          supplier: "calculated",
          investmentCosts: "4781.22 €",
          unitPrice1: "6.97 €",
          bomPrice1: "6.97 €",
          total1: "4850.92 €",
          unitPrice2: "2.32 €",
          bomPrice2: "2.32 €",
          total2: "7104.55 €"
        },
        {
          key: 15,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "6412.88 €",
          unitPrice1: "7.12 €",
          bomPrice1: "7.12 €",
          total1: "6484.08 €",
          unitPrice2: "2.37 €",
          bomPrice2: "2.37 €",
          total2: "8786.21 €"
        },
        {
          key: 16,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "21.38 €",
          bomPrice1: "21.38 €",
          total1: "213.80 €",
          unitPrice2: "10.69 €",
          bomPrice2: "10.69 €",
          total2: "10690.00 €"
        },
        {
          key: 17,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "24.00 €",
          bomPrice1: "24.00 €",
          total1: "240.00 €",
          unitPrice2: "12.00 €",
          bomPrice2: "12.00 €",
          total2: "12000.00 €"
        },
        {
          key: 18,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Vacuum casting",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "25.14 €",
          bomPrice1: "25.14 €",
          total1: "251.40 €",
          unitPrice2: "12.57 €",
          bomPrice2: "12.57 €",
          total2: "12570.00 €"
        },
        {
          key: 19,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Vacuum casting",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "29.74 €",
          bomPrice1: "29.74 €",
          total1: "297.40 €",
          unitPrice2: "14.87 €",
          bomPrice2: "14.87 €",
          total2: "14870.00 €"
        },
        {
          key: 20,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "9516.78 €",
          unitPrice1: "12.26 €",
          bomPrice1: "12.26 €",
          total1: "9639.38 €",
          unitPrice2: "6.13 €",
          bomPrice2: "6.13 €",
          total2: "15646.78 €"
        },
        {
          key: 21,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "Injection Moulding",
          supplier: "***",
          investmentCosts: "10000.00 €",
          unitPrice1: "12.00 €",
          bomPrice1: "12.00 €",
          total1: "10120.00 €",
          unitPrice2: "6.00 €",
          bomPrice2: "6.00 €",
          total2: "16000.00 €"
        },
        {
          key: 22,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "3d printing - FDM",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "55.74 €",
          bomPrice1: "55.74 €",
          total1: "557.40 €",
          unitPrice2: "27.87 €",
          bomPrice2: "27.87 €",
          total2: "27870.00 €"
        },
        {
          key: 23,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "3d printing - SLS",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "60.96 €",
          bomPrice1: "60.96 €",
          total1: "609.60 €",
          unitPrice2: "30.48 €",
          bomPrice2: "30.48 €",
          total2: "30480.00 €"
        },
        {
          key: 24,
          name: "Boden1",
          article: "Boden1 (102)",
          singleQuantity: 1,
          process: "3d printing - MJF",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "145.34 €",
          bomPrice1: "145.34 €",
          total1: "1453.40 €",
          unitPrice2: "72.67 €",
          bomPrice2: "72.67 €",
          total2: "72670.00 €"
        },
      ]
    },
    {
      key: 28,
      name: "Boden2",
      article: "Boden2 (103)",
      singleQuantity: 4,
      process: "Milling",
      supplier: "***",
      investmentCosts: "",
      unitPrice1: "14.58 €",
      bomPrice1: "14.58 €",
      total1: "145.80 €",
      unitPrice2: "6.23 €",
      bomPrice2: "6.23 €",
      total2: "6230.00 €",
      children: [
        {
          key: 29,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "17.50 €",
          bomPrice1: "17.50 €",
          total1: "175.00 €",
          unitPrice2: "7.48 €",
          bomPrice2: "7.48 €",
          total2: "7480.00 €"
        },
        {
          key: 30,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "18.00 €",
          bomPrice1: "18.00 €",
          total1: "180.00 €",
          unitPrice2: "7.69 €",
          bomPrice2: "7.69 €",
          total2: "7690.00 €"
        },
        {
          key: 31,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "21.58 €",
          bomPrice1: "21.58 €",
          total1: "215.80 €",
          unitPrice2: "9.22 €",
          bomPrice2: "9.22 €",
          total2: "9220.00 €"
        },
        {
          key: 32,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Milling",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "23.94 €",
          bomPrice1: "23.94 €",
          total1: "239.40 €",
          unitPrice2: "10.23 €",
          bomPrice2: "10.23 €",
          total2: "10230.00 €"
        },
        {
          key: 33,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "25.00 €",
          bomPrice1: "25.00 €",
          total1: "250.00 €",
          unitPrice2: "10.68 €",
          bomPrice2: "10.68 €",
          total2: "10680.00 €"
        },
        {
          key: 34,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Die casting + milling",
          supplier: "calculated",
          investmentCosts: "7781.62 €",
          unitPrice1: "9.81 €",
          bomPrice1: "9.81 €",
          total1: "7879.72 €",
          unitPrice2: "3.15 €",
          bomPrice2: "3.15 €",
          total2: "10931.62 €"
        },
        {
          key: 35,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Deep drawing",
          supplier: "calculated",
          investmentCosts: "3451.12 €",
          unitPrice1: "15.54 €",
          bomPrice1: "15.54 €",
          total1: "3606.52 €",
          unitPrice2: "8.15 €",
          bomPrice2: "8.15 €",
          total2: "11601.12 €"
        },
        {
          key: 36,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Sand Casting + Milling",
          supplier: "calculated",
          investmentCosts: "2418.52 €",
          unitPrice1: "17.51 €",
          bomPrice1: "17.51 €",
          total1: "2593.62 €",
          unitPrice2: "9.51 €",
          bomPrice2: "9.51 €",
          total2: "11928.52 €"
        },
        {
          key: 37,
          name: "Boden2",
          article: "Boden2 (103)",
          singleQuantity: 4,
          process: "Weld construction",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "52.84 €",
          bomPrice1: "52.84 €",
          total1: "528.40 €",
          unitPrice2: "22.58 €",
          bomPrice2: "22.58 €",
          total2: "22580.00 €"
        },
      ]
    },
    {
      key: 57,
      name: "Deckel2",
      article: "Deckel2 (104)",
      singleQuantity: 8,
      process: "Laser cutting",
      supplier: "calculated",
      investmentCosts: "",
      unitPrice1: "4.12 €",
      bomPrice1: "4.12 €",
      total1: "41.20 €",
      unitPrice2: "2.12 €",
      bomPrice2: "2.12 €",
      total2: "2120.00 €",
      children: [
        {
          key: 58,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Water jet",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "5.22 €",
          bomPrice1: "5.22 €",
          total1: "52.20 €",
          unitPrice2: "3.34 €",
          bomPrice2: "3.34 €",
          total2: "3340.00 €"
        },
        {
          key: 59,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "9.11 €",
          bomPrice1: "9.11 €",
          total1: "91.10 €",
          unitPrice2: "3.89 €",
          bomPrice2: "3.89 €",
          total2: "3890.00 €"
        },
        {
          key: 60,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "10.94 €",
          bomPrice1: "10.94 €",
          total1: "109.40 €",
          unitPrice2: "4.68 €",
          bomPrice2: "4.68 €",
          total2: "4680.00 €"
        },
        {
          key: 61,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "11.25 €",
          bomPrice1: "11.25 €",
          total1: "112.50 €",
          unitPrice2: "4.81 €",
          bomPrice2: "4.81 €",
          total2: "4810.00 €"
        },
        {
          key: 62,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Milling",
          supplier: "calculated",
          investmentCosts: "",
          unitPrice1: "13.49 €",
          bomPrice1: "13.49 €",
          total1: "134.90 €",
          unitPrice2: "5.76 €",
          bomPrice2: "5.76 €",
          total2: "5760.00 €"
        },
        {
          key: 63,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "14.96 €",
          bomPrice1: "14.96 €",
          total1: "149.60 €",
          unitPrice2: "6.39 €",
          bomPrice2: "6.39 €",
          total2: "6390.00 €"
        },
        {
          key: 64,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Milling",
          supplier: "***",
          investmentCosts: "",
          unitPrice1: "15.63 €",
          bomPrice1: "15.63 €",
          total1: "156.30 €",
          unitPrice2: "6.68 €",
          bomPrice2: "6.68 €",
          total2: "6680.00 €"
        },
        {
          key: 65,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Stamping",
          supplier: "calculated",
          investmentCosts: "4951.23 €",
          unitPrice1: "17.81 €",
          bomPrice1: "17.81 €",
          total1: "5129.33 €",
          unitPrice2: "2.12 €",
          bomPrice2: "2.12 €",
          total2: "7071.23 €"
        },
        {
          key: 66,
          name: "Deckel2",
          article: "Deckel2 (104)",
          singleQuantity: 8,
          process: "Deep drawing",
          supplier: "calculated",
          investmentCosts: "1821.59 €",
          unitPrice1: "15.52 €",
          bomPrice1: "15.52 €",
          total1: "1976.79 €",
          unitPrice2: "5.81 €",
          bomPrice2: "5.81 €",
          total2: "7631.59 €"
        },
      ]
    },
  ];

export default function GetConstant(props: Props) : FormFieldType[] | ColumnProps<any>[] | SelectOption[] {
    const { t } = useTranslation(['common', 'request']);
    const { user } = useUserContext();

    const renderStatus = (status: number) => {
        switch(status) {
            case 0:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.pending")} color="warning" variant="outlined" />
                    </Stack>
                )
            case 1:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.accepted")} color="success" variant="outlined" />
                    </Stack>
                )
            case 2:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.rejected")} color="error" variant="outlined" />
                    </Stack>
                )
            case 3:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.forwarded")} color="primary" variant="outlined" />
                    </Stack>
                )
            default:
                return (
                    <Stack direction="row" spacing={1}>
                        <Chip label={t("request:content.label.unknown")} color="default" variant="outlined" />
                    </Stack>
                )
        }
    }

    if(props.name === 'requestTableColumns'){
        return [
            {
                title: "",
                dataIndex: 'read',
                key: 'read',
                render: (read: boolean, record: Request) => {
                    const newRequest = props.newRequests?.find(request => request.key === record.key)
                    if(!newRequest) return
                    if(newRequest && newRequest.read === true) return
                    if (newRequest && newRequest.read === false) {
                    return (
                        <Grid container justifyContent="center">
                            <Grid item>
                                {newRequest && newRequest.read === false
                                   && <Chip sx={{backgroundColor:'#90D9F5'}} label={t("common:content.label.new")} />
                                }
                            </Grid>
                        </Grid>
                    )
                    }
                }
            },
            {
                title: t("request:content.label.requestNumber"),
                dataIndex: "self",
                key: "self",
                render: (self: any) => getPKfromSelf(self)
            },
            {
                title: t("request:content.label.article"),
                dataIndex: "article",
                key: "article",
                render: (article: any) => article?.name
            },
            {
                title: t("common:content.label.createdAt"),
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.updatedAt"),
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.destinationCountry"),
                dataIndex: "country",
                key: "country",
            },
            {
                title: t("request:content.label.remarks"),
                dataIndex: "remarks",
                key: "remarks",
            },
            {
                title: t("request:content.label.certificates"),
                dataIndex: "certificates",
                key: "certificates",
            },
            {
                title: t("request:content.label.description"),
                dataIndex: "description",
                key: "description",
            },
            {
                title: t("request:content.label.quantities"),
                dataIndex: "quantities",
                key: "quantities",
                render: (quantities: any) => quantities?.map((q: number) => q).join(", ")
            },
            {
                title: t("request:content.label.wishDate"),
                dataIndex: "wishDate",
                key: "wishDate",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.status"),
                dataIndex: "status",
                key: "status",
                render: (status: number) => renderStatus(status)
            },
            {
                title: t("request:content.label.priority"),
                dataIndex: "priority",
                key: "priority",
                render: (priority: boolean) =>
                    <>
                        {priority ? <Check /> : <Close />}
                    </>
            },
            {
                title: t('common:content.label.editors'),
                key: 'editors',
                dataIndex: 'editors',
                render: (text: string, record: Request) => {
                    if (Array.isArray(record.editors)) {
                      return (
                        <>
                          {record.editors.map((e: any) => (
                            <Chip key={e.id} label={`${e.first_name} ${e.last_name}`} style={{ marginRight: '5px' }} />
                          ))}
                        </>
                      )
                    } else {
                      return ''
                    }
                  },
            },
            {
                title: t('common:content.label.action'),
                key: 'action',
                dataIndex: 'action',
                render: (text: string, record: any) => (
                    <Stack direction="column" spacing={1}>
                        <Button
                            id='offerByAssembleanButton'
                            size="small"
                            variant="contained"
                            onClick={e => props.handleOffer && props.handleOffer(e, record)}
                            disabled={record.status === 2}
                        >
                            {t('request:interaction.button.createOffer')}
                        </Button>
                        <Button
                            id='declineRequestButton'
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={e => props.handleDecline && props.handleDecline(e, record)}
                            disabled={record.status === 2 || record.status === 1}
                        >
                            {t('request:interaction.button.declineRequest')}
                        </Button>
                    </Stack>
                ),
            },
        ].filter((column) => (!isPrivileged(user, "STAFF") && column.key !== 'editors' && column.key !== "priority" && column.key !== "action") || isPrivileged(user, "STAFF")) as ColumnProps<any>[]
    }

    if(props.name === 'offerTableColumns'){
        return [
            {
                title: "",
                dataIndex: 'read',
                key: 'read',
                render: (read: boolean, record: Offer) => {
                    const newOffer = props.newOffers?.find(offer => offer.key === record.key)
                    if(!newOffer) return
                    if(newOffer && newOffer.read === true) return
                    if (newOffer && newOffer.read === false) {
                    return (
                        <Grid container justifyContent="center">
                            <Grid item>
                                {newOffer && newOffer.read === false
                                   && <Chip sx={{backgroundColor:'#90D9F5'}} label={t("common:content.label.new")} />
                                }
                            </Grid>
                        </Grid>
                    )
                    }
                }
            },
            {
                title: t("request:content.label.offerNumber"),
                dataIndex: "self",
                key: "self",
                render: (self: any) => getPKfromSelf(self)
            },
            {
                title: t("request:content.label.requestNumber"),
                dataIndex: "request",
                key: "request",
                render: (request: any) => request?.self && getPKfromSelf(request.self)
            },
            {
                title: t("request:content.label.article"),
                dataIndex: "article",
                key: "article",
                render: (article: any) => article?.name
            },
            {
                title: t("common:content.label.createdAt"),
                dataIndex: "createdAt",
                key: "createdAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("common:content.label.updatedAt"),
                dataIndex: "updatedAt",
                key: "updatedAt",
                render: (date: moment.MomentInput) => moment(date).format("DD.MM.YYYY")
            },
            {
                title: t("request:content.label.remarks"),
                dataIndex: "remarks",
                key: "remarks",
            },
            {
                title: t("request:content.label.prices"),
                dataIndex: "prices",
                key: "prices",
                render: (prices: PriceObject) => {
                    return (
                        <>
                            {Object.keys(prices).map((k: string) => (
                                <div key={k}>
                                    <strong>{k}</strong>: {prices[k] + " €"}
                                </div>
                            ))}
                        </>
                    )
                }
            },
            {
                title: t("request:content.label.status"),
                dataIndex: "status",
                key: "status",
                render: (status: number) => renderStatus(status)
            },
            {
                title: t('common:content.label.editors'),
                key: 'editors',
                dataIndex: 'editors',
                render: (text: string, record: Request) => {
                    if (Array.isArray(record.editors)) {
                      return (
                        <>
                          {record.editors.map((e: any) => (
                            <Chip key={e.id} label={`${e.first_name} ${e.last_name}`} style={{ marginRight: '5px' }} />
                          ))}
                        </>
                      )
                    } else {
                      return ''
                    }
                  },
            },
            {
                title: t('common:content.label.action'),
                key: 'action',
                dataIndex: 'action',
                render: (text: string, record: any) => (
                    <Stack direction="column" spacing={1}>
                        <Button
                            id='acceptOfferButton'
                            size="small"
                            variant="contained"
                            onClick={e => props.handleContract && props.handleContract(e, record)}
                            disabled={record.status === 2}
                        >
                            {t('request:interaction.button.createContract')}
                        </Button>
                        <Button
                            id='declineOfferButton'
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={e => props.handleDecline && props.handleDecline(e, record)}
                            disabled={record.status === 2 || record.status === 1}
                        >
                            {t('request:interaction.button.declineOffer')}
                        </Button>
                    </Stack>
                ),
            },
        ].filter((column) => (!isPrivileged(user, "STAFF") && column.key !== 'editors') || (isPrivileged(user, "STAFF") && column.key !== "action")) as ColumnProps<any>[]
    }

    if(props.name === "certificateOptions") {
        return [
          { value: 'AS 9100D', label: 'AS 9100D' },
          { value: 'DIN EN ISO 13485', label: 'DIN EN ISO 13485' },
          { value: 'DIN EN ISO 9001', label: 'DIN EN ISO 9001' },
          { value: 'NADCAP', label: 'NADCAP' },
          { value: 'ISO 14001', label: 'ISO 14001' },
          { value: 'IATF 16949', label: 'IATF 16949' },
          { value: 'ISO 27001', label: 'ISO 27001' },
          { value: 'BS7799', label: 'BS7799' },
          { value: 'ISO 50001', label: 'ISO 50001' },
          { value: 'EN ISO 17065', label: 'EN ISO 17065' },
          { value: 'SA8000', label: 'SA8000' },
          { value: 'EMAS', label: 'EMAS' },
          { value: 'DIN EN 1090', label: 'DIN EN 1090' },
          { value: 'DIN EN ISO 3834', label: 'DIN EN ISO 3834' },
          { value: 'DIN 2304', label: 'DIN 2304' },
          { value: 'DIN EN ISO 13585', label: 'DIN EN ISO 13585' },
          { value: 'DIN ISO 11745', label: 'DIN ISO 11745' },
        ] as SelectOption[]
    }

    if(props.name === "requestFormFieldsDisabled") {
        return [
            {
                type: 'text',
                key: 'name',
                label: t('contract:content.label.selectedProduct'),
                xs: 12,
                sm: 6,
            },
            {
                type: 'text',
                key: 'number',
                label: t('contract:content.label.articleNumber'),
                xs: 12,
                sm: 6,
            },
        ]
    }

    if(props.name === "requestFormFields") {
        return [
            {
                type: 'multiline',
                key: 'description',
                label: t('request:content.label.description'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: "select-search",
                key: "certificate",
                label: t("common:content.label.certificate"),
                options: props.certificateOptions,
                validation: false,
                required: false,
                xs: 12,
                sm: 6,
            },
            {
                type: 'date',
                key: 'wishDate',
                label: t('request:content.label.wishDate'),
                required: false,
                validation: false,
                xs: 12,
                sm: 6,
            },
        ] as FormFieldType[]
    }

    if(props.name === "requestFormFieldsStaff") {
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('request:content.label.remarks'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: 'select-search-multi',
                key: 'editors',
                label: t('common:content.label.editors'),
                options: [] as SelectOption[],
                loadData: loadAllStaffUsers,
                required: false,
                validation: false,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: 'checkbox',
                key: 'priority',
                label: t('request:content.label.priorityText'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
        ]
    }

    if(props.name === "offerFormFields") {
        return [
            {
                type: 'multiline',
                key: 'remarks',
                label: t('request:content.label.remarks'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "offerFormFieldsDisabled") {
        return [
            {
                type: 'text',
                key: 'name',
                label: t('contract:content.label.selectedProduct'),
                xs: 12,
                sm: 6,
            },
            {
                type: 'text',
                key: 'number',
                label: t('contract:content.label.articleNumber'),
                xs: 12,
                sm: 6,
            },
        ] as FormFieldType[]
    }

    if(props.name === "offerFormFieldsStaff") {
        return [
            {
                type: 'select-search-multi',
                key: 'editors',
                label: t('common:content.label.editors'),
                options: [] as SelectOption[],
                loadData: loadAllStaffUsers,
                required: false,
                validation: false,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            },
            {
                type: 'multiline',
                key: 'remarks',
                label: t('request:content.label.remarks'),
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
            }
        ] as FormFieldType[]
    }

    if(props.name === "addressFormFields") {
        return [
            {
                type: 'select-search',
                key: 'country',
                label: t('request:content.label.destinationCountry'),
                loadData: loadCountries,
                required: true,
                validation: true,
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12,
              },
        ]
      }

    return [] as FormFieldType[] | ColumnProps<any>[] | SelectOption[];
}
/* -------------------------- Design imports start -------------------------- */
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import Page from '../../components/layout/Page'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useMobileService from '../../hooks/useMobileService'
import { HTTPMethod } from '../../utils/types'
import LogTool from '../../logger/logTools'
import logo from '../../assets/logo_black.svg'
import i18next from 'i18next'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import { Form } from '../../features/Form'
import Button from '../../components/Button'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  setUser: (user: any) => void
}

type UserAttributes = {
  [key: string]: string
}

type LoginFormFieldType = {
  placeholder: string
  label: string
  type: string
  required?: boolean
  error?: boolean
  helperText?: string | null
  inputProps?: any
}

/* -------------------------------------------------------------------------- */
/*                               Component start                              */
/* -------------------------------------------------------------------------- */
export default function SignUpPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  //const { setUser } = props
  const history = useHistory()
  const { t } = useTranslation(['common'])
  let currentLng: string = i18next.language
  if (currentLng?.match(/en/)) {
    // convert en-US to en
    currentLng = 'en'
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  const log = new LogTool({ context: 'SignUpPage', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [errorMessage, setErrorMessage] = useState('')
  const [data, setData] = useState<UserAttributes>({
    first_name: '',
    last_name: '',
    email: '',
    repeat_password: '',
    password: '',
    organization: '',
  })
  const isMobile = useMobileService()
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleSignUp = async (e: any) => {
    e.preventDefault()
    if (dataIsValid(data)) {
      log.debug(data)
      const response = await fetch(process.env.REACT_APP_API_URL + 'accounts/register', {
        method: HTTPMethod.POST,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          password2: data.repeat_password,
          organization: data.organization,
          language: currentLng,
        }),
      })
      if (response.status === 200) {
        setSuccess(true)
        history.push('/check-email')
      } else {
        setError(true)
        setErrorMessage(t('content.auth.signUpErrorText') || 'Error')
      }
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (dataIsValid(data)) {
      handleSignUp(event)
      /* history.push('/items') */
      /*history.push({ pathname: '/verify-email', state: { email: data.email, password: data.password } })*/
    } else {
      setInvalidPassword(true)
    }
  }

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */

  const dataIsValid = (data: UserAttributes) => {
    return (
      data.first_name &&
      data.last_name &&
      data.email &&
      emailRegex.test(data.email) &&
      data.password &&
      passwordRegex.test(data.password) &&
      data.repeat_password &&
      data.password === data.repeat_password &&
      data.organization
    )
  }

  const loginFormfields: LoginFormFieldType[] = [
    {
      type: 'first_name',
      label: t('common:content.loginForm.firstName') + ' *',
      placeholder: t('common:content.loginForm.firstNamePlaceholder'),
      inputProps: { required: true, autoComplete: 'given-name' },
      error: (data.first_name && !data.first_name.length) || false,
    },
    {
      type: 'last_name',
      label: t('common:content.loginForm.lastName') + ' *',
      placeholder: t('common:content.loginForm.lastNamePlaceholder'),
      inputProps: { required: true, autoComplete: 'family-name' },
      error: (data.last_name && !data.last_name.length) || false,
    },
    {
      type: 'email',
      label: t('common:content.loginForm.email') + ' *',
      placeholder: t('common:content.loginForm.emailPlaceholder'),
      inputProps: { required: true, autoComplete: 'username' },
      helperText:
        data.email && !emailRegex.test(data.email)
          ? t('common:content.loginForm.emailHelperText')
          : '',
      error: (data.email && !emailRegex.test(data.email)) || false,
    },
    {
      type: 'password',
      label: t('common:content.loginForm.password') + ' *',
      placeholder: t('common:content.loginForm.passwordPlaceholder'),
      inputProps: { required: true, autoComplete: 'new-password' },
      helperText: t('common:content.loginForm.passwordHelperText'),
      error: (data.password && !passwordRegex.test(data.password) && invalidPassword) || false,
    },
    {
      type: 'repeat_password',
      label: t('common:content.loginForm.repeatPassword') + ' *',
      placeholder: t('common:content.loginForm.repeatPasswordPlaceholder'),
      inputProps: { required: true, autoComplete: 'new-password' },
      helperText:
        data.password !== data.repeat_password
          ? t('common:content.loginForm.repeatPasswordHelperText')
          : '',
      error: data.password !== data.repeat_password || false,
    },
    {
      type: 'organization',
      label: t('common:content.loginForm.company') + ' *',
      placeholder: t('common:content.loginForm.companyPlaceholder'),
      inputProps: { required: true, autoComplete: 'company' },
      helperText: t('common:content.loginForm.companyHelperText'),
      error: (data.company_name && !data.company_name.length) || false,
    },
  ]

  if (isMobile) {
    return (
      <Page>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
            <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
              <img
                src={logo}
                alt="logo"
                style={{ width: '200px', height: '100px', marginBottom: '-30px' }}
              />
              <Typography variant="h5">{t('content.auth.register')}</Typography>
              <form onSubmit={handleSubmit} style={{ width: isMobile ? '100%' : '60%' }}>
                {loginFormfields.map((field: LoginFormFieldType, index: number) => {
                  if (field.type !== 'password' && field.type !== 'repeat_password') {
                  return (
                    <Box mt={2} key={index}>
                      <TextField
                        id={field.type}
                        label={field.label}
                        type={
                          field.type === 'password' || field.type === 'repeat_password'
                            ? 'password'
                            : field.type
                        }
                        variant="outlined"
                        fullWidth
                        value={data[field.type]}
                        onChange={e => setData({ ...data, [field.type]: e.target.value })}
                        placeholder={field.placeholder}
                        inputProps={field.inputProps}
                        helperText={field.helperText}
                        error={field.error}
                      />
                    </Box>
                  )}
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor={field.type}>{field.label}</InputLabel>
                  <OutlinedInput
                    id={field.type}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    type={showPassword ? 'text' : 'password'}
                    value={data[field.type]}
                    onChange={e => setData({ ...data, [field.type]: e.target.value })}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    label={field.label}
                    placeholder={field.placeholder}
                    inputProps={field.inputProps}
                    error={field.error}
                  />
                  <FormHelperText>{field.helperText}</FormHelperText>
                </FormControl>
                })}
                <Box mt={2}>
                  <Button type="submit" variant="contained" fullWidth>
                    {t('content.auth.register')}
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button
                    sx={{ marginBottom: '1rem' }}
                    variant="contained"
                    color="info"
                    fullWidth
                    onClick={() => history.push('/signIn')}
                  >
                    {t('content.auth.backToSignIn')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Page>
    )
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={4}
        sx={{ marginBottom: '20px' }}
      >
        <img src={logo} alt="logo" style={{ width: '200px', height: '100px' }} />

        <Typography variant="h5">{t('content.auth.registerAnOrganizationText')}</Typography>
        <form onSubmit={handleSubmit} style={{ width: isMobile ? '100%' : '60%' }}>
          {loginFormfields.map((field: LoginFormFieldType, index: number) => {
            if (field.type !== 'password' && field.type !== 'repeat_password') {
              return (
                <Box mt={2} key={index}>
                  <TextField
                    id={field.type}
                    label={field.label}
                    type={
                      field.type === 'password' || field.type === 'repeat_password'
                        ? 'password'
                        : field.type
                    }
                    variant="outlined"
                    fullWidth
                    value={data[field.type]}
                    onChange={e => setData({ ...data, [field.type]: e.target.value })}
                    placeholder={field.placeholder}
                    inputProps={field.inputProps}
                    helperText={field.helperText}
                    error={field.error}
                  />
                </Box>
              )
            }
            return (
              <Box mt={2} key={index}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor={field.type}>{field.label}</InputLabel>
                  <OutlinedInput
                    id={field.type}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    type={showPassword ? 'text' : 'password'}
                    value={data[field.type]}
                    onChange={e => setData({ ...data, [field.type]: e.target.value })}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    label={field.label}
                    placeholder={field.placeholder}
                    inputProps={field.inputProps}
                    error={field.error}
                  />
                  <FormHelperText>{field.helperText}</FormHelperText>
                </FormControl>
              </Box>
            )
          })}
          <Box mt={2}>
            <Typography variant="body2" color="error">
              {error && errorMessage}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              {t('content.auth.registerInfoText1')}
              <Link href="https://assemblean.com/terms-and-conditions/" target="_blank">
                {t('content.auth.terms')}
              </Link>
              {t('content.auth.registerInfoText2')}
              <Link href="https://assemblean.com/privacy-policy/" target="_blank">
                {t('content.auth.privacy')}
              </Link>
              {t('content.auth.registerInfoText3')}
            </Typography>
          </Box>
          <Box mt={2}>
            <Button
              type="submit"
              variant="contained"
              style={{ color: 'whitesmoke', textTransform: 'none' }}
              fullWidth
            >
              {t('common:content.auth.register')}
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              style={{ textTransform: 'none' }}
              onClick={() => history.push('/signIn')}
            >
              {t('common:content.auth.backToSignIn')}
            </Button>
          </Box>
        </form>
      </Box>
    </Page>
  )
}

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const languages = ['en', 'de']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'address',
      'chat',
      'common',
      'contract',
      'customer',
      'employee',
      'files',
      'item',
      'organization',
      'permissions',
      'request',
      'profile',
      'role',
      'stock',
      'supplier',
      'gantt',
      'sharepoint',
    ],
    defaultNS: 'common',
    fallbackLng: 'en',
    detection: {
      /* --------------------------- Detection strategy --------------------------- */
      // First: check if the querystring of the url specifies a language. This has the
      //   highest priority as we use this to direct users from our homepage to
      //   the platform, specifying the same language that they chose on the homepage.
      //   This also most likely affects users that not have visited our platform before.
      // Second: check the i18next cookie. The Cookie is used to cache the preferred
      //   language. This most likely affects users that visit our platform directly and
      //   that that not visit for the first time.
      //   After login this cookie is overwritten with the language code that is
      //   stored in the user preferences object in the backend.
      // Third: if neither the querystring nor the cookie specify a language, use the
      //   the same language that the users browser is set to. If we do not support
      //   that language, use the english translation. This affects users that have
      //   not visited the platform before, but did not come here via the homepage.
      //
      // Note: regardless of how the preferred language is detected a cookie caching
      //   the language setting is set. (if not changed, the cookie is named i18next)
      /* -------------------------------------------------------------------------- */
      order: ['querystring', 'cookie', 'navigator'], // ,'htmlTag', 'cookie'
      caches: ['cookie'],
    },
    debug: process.env.NODE_ENV === 'development',
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n

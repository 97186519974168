/* -------------------------- Design imports start -------------------------- */
import { Form, FormFieldType } from '../../Form'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import { useEffect, useState } from 'react'
import GetFormConstant from '../utils/formconstants'
import { mapCategoryAndProcessToProcess } from '../utils/functions'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function ProcessForm(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { formState } = props
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [visible, setVisible] = useState<string>('')
  /* ----------------------------- Flag states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (
      formState.state?.manufacturingProcess?.category &&
      formState.state?.manufacturingProcess?.process
    ) {
      const process = mapCategoryAndProcessToProcess(
        formState.state?.manufacturingProcess?.category,
        formState.state?.manufacturingProcess?.process
      )
      setVisible(process)
      /* formState.setState({ ...formState.state, manufacturingProcess: {
                category: formState.state?.manufacturingProcess?.category,
                process: formState.state?.manufacturingProcess?.process,
            }}) */
    }
  }, [
    formState.state?.manufacturingProcess?.category,
    formState.state?.manufacturingProcess?.process,
  ])

  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------ Callback functions start ------------------------ */
  const onChange = (value: any) => {
    const manufacturingProcess = { ...formState.state.manufacturingProcess, ...value }
    formState.setState({ ...formState.state, manufacturingProcess })
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const assemblyManuallyFormFields = GetFormConstant({
    name: 'assembly_manually',
  }) as FormFieldType[]
  const assemblyRobotFormFields = GetFormConstant({ name: 'assembly_robot' }) as FormFieldType[]
  const joiningWeldingFormFields = GetFormConstant({ name: 'joining_welding' }) as FormFieldType[]
  const joiningSolderingFormFields = GetFormConstant({
    name: 'joining_soldering',
  }) as FormFieldType[]
  const joiningGlueingFormFields = GetFormConstant({ name: 'joining_glueing' }) as FormFieldType[]
  const cncMillingFormFields = GetFormConstant({ name: 'cnc_milling' }) as FormFieldType[]
  const cncDrillingFormFields = GetFormConstant({ name: 'cnc_drilling' }) as FormFieldType[]
  const cncTurningFormFields = GetFormConstant({ name: 'cnc_turning' }) as FormFieldType[]
  const cncGrindingFormFields = GetFormConstant({ name: 'cnc_grinding' }) as FormFieldType[]
  const castingDieCastFormFields = GetFormConstant({ name: 'casting_die_cast' }) as FormFieldType[]
  const castingInjectionMouldingFormFields = GetFormConstant({
    name: 'casting_injection_moulding',
  }) as FormFieldType[]
  const castingSandCastingFormFields = GetFormConstant({
    name: 'casting_sand_casting',
  }) as FormFieldType[]
  const castingInvestmentCastingFormFields = GetFormConstant({
    name: 'casting_investment_casting',
  }) as FormFieldType[]
  const pcbEtchingFormFields = GetFormConstant({name: 'pcb_etching'}) as FormFieldType[]
  const postProcessingBlastingFormFields = GetFormConstant({
    name: 'postprocessing_blasting',
  }) as FormFieldType[]
  const postProcessingBrushFormFields = GetFormConstant({
    name: 'postprocessing_brush',
  }) as FormFieldType[]
  const postProcessingGrindingFormFields = GetFormConstant({
    name: 'postprocessing_grinding',
  }) as FormFieldType[]
  const postProcessingPolishingFormFields = GetFormConstant({
    name: 'postprocessing_polishing',
  }) as FormFieldType[]
  const postProcessingPaintingFormFields = GetFormConstant({
    name: 'postprocessing_painting',
  }) as FormFieldType[]
  const postProcessingAnodizingFormFields = GetFormConstant({
    name: 'postprocessing_anodizing',
  }) as FormFieldType[]
  const postProcessingElectroPlatedNickelPlatingFormFields = GetFormConstant({
    name: 'postprocessing_electroPlatedNickelPlating',
  }) as FormFieldType[]
  const postProcessingElectroPlatedChromePlatingFormFields = GetFormConstant({
    name: 'postprocessing_electroPlatedChromePlating',
  }) as FormFieldType[]
  const postProcessingLaserEngravingFormFields = GetFormConstant({
    name: 'postprocessing_laserEngraving',
  }) as FormFieldType[]
  const postProcessingPowderCoatingFormFields = GetFormConstant({
    name: 'postprocessing_powderCoating',
  }) as FormFieldType[]
  const postProcessingElectroGalvanizingFormFields = GetFormConstant({
    name: 'postprocessing_electroGalvanizing',
  }) as FormFieldType[]
  const postProcessingCustomProcessFormFields = GetFormConstant({
    name: 'postprocessing_customProcess',
  }) as FormFieldType[]
  const sheetMetalTubeLaserCuttingFormFields = GetFormConstant({
    name: 'sheetmetal_tubeLaserCutting',
  }) as FormFieldType[]
  const sheetMetalPunchingFormFields = GetFormConstant({
    name: 'sheetmetal_punching',
  }) as FormFieldType[]
  const sheetMetalTubeBendingFormFields = GetFormConstant({
    name: 'sheetmetal_bending',
  }) as FormFieldType[]
  const sheetMetalWeldingFormFields = GetFormConstant({
    name: 'sheetmetal_folding',
  }) as FormFieldType[]
  const sheetMetalLaserCuttingFormFields = GetFormConstant({
    name: 'sheetmetal_laserCutting',
  }) as FormFieldType[]
  const printing3DFormFields = GetFormConstant({ name: 'printing3D_3DPrinting' }) as FormFieldType[]
  const printing3DFDMFormFields = GetFormConstant({ name: 'printing3D_FDM' }) as FormFieldType[]
  const printing3DSLAFormFields = GetFormConstant({ name: 'printing3D_SLA' }) as FormFieldType[]
  const printing3DSLSFormFields = GetFormConstant({ name: 'printing3D_SLS' }) as FormFieldType[]
  const printing3DSLMFormFields = GetFormConstant({ name: 'printing3D_SLM' }) as FormFieldType[]
  const printing3DDLPFormFields = GetFormConstant({ name: 'printing3D_DLP' }) as FormFieldType[]
  const printing3DPBFFormFields = GetFormConstant({ name: 'printing3D_PBF' }) as FormFieldType[]
  const printing3DDMLSFormFields = GetFormConstant({ name: 'printing3D_DMLS' }) as FormFieldType[]
  const printing3DMJFFormFields = GetFormConstant({ name: 'printing3D_MJF' }) as FormFieldType[]
  const standardComponentFormFields = GetFormConstant({
    name: 'standardComponent_standardComponent',
  }) as FormFieldType[]
  const rawMaterialFormFields = GetFormConstant({
    name: 'rawMaterial_rawMaterial',
  }) as FormFieldType[]
  const qualityControlFormFields = GetFormConstant({
    name: 'qualityControl_qualityControl',
  }) as FormFieldType[]
  const customProcessFormFields = GetFormConstant({
    name: 'customProcess_customProcess',
  }) as FormFieldType[]

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      {visible === 'assembly_manually' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={assemblyManuallyFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'assembly_robot' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={assemblyRobotFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'joining_welding' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={joiningWeldingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'joining_soldering' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={joiningSolderingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'joining_glueing' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={joiningGlueingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'cnc_milling' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={cncMillingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'cnc_drilling' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={cncDrillingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'cnc_turning' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={cncTurningFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'cnc_grinding' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={cncGrindingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'casting_die_cast' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={castingDieCastFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'casting_injection_moulding' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={castingInjectionMouldingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'casting_sand_casting' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={castingSandCastingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'casting_investment_casting' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={castingInvestmentCastingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'pcb_etching' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={pcbEtchingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_blasting' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingBlastingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_brush' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingBrushFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_grinding' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingGrindingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_polishing' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingPolishingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_painting' && (
        <div>
          <Form
            sx={{ marginTop: '1rem' }}
            formFields={postProcessingPaintingFormFields}
            formObject={formState.state.manufacturingProcess!}
            onChange={onChange}
            editing={true}
          />
        </div>
      )}
      {visible === 'postprocessing_anodizing' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingAnodizingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_electroPlatedNickelPlating' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingElectroPlatedNickelPlatingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_electroPlatedChromePlating' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingElectroPlatedChromePlatingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_laserEngraving' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingLaserEngravingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_powderCoating' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingPowderCoatingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_electroGalvanizing' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingElectroGalvanizingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'postprocessing_customProcess' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={postProcessingCustomProcessFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'sheetmetal_tubeLaserCutting' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={sheetMetalTubeLaserCuttingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'sheetmetal_punching' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={sheetMetalPunchingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'sheetmetal_bending' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={sheetMetalTubeBendingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'sheetmetal_folding' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={sheetMetalWeldingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'sheetmetal_laserCutting' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={sheetMetalLaserCuttingFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_3DPrinting' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_FDM' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DFDMFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_SLA' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DSLAFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_SLS' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DSLSFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_SLM' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DSLMFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_DLP' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DDLPFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_PBF' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DPBFFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_DMLS' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DDMLSFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'printing3D_MJF' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={printing3DMJFFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'standardComponent_standardComponent' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={standardComponentFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'rawMaterial_rawMaterial' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={rawMaterialFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'qualityControl_qualityControl' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={qualityControlFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
      {visible === 'customProcess_customProcess' && (
        <Form
          sx={{ marginTop: '1rem' }}
          formFields={customProcessFormFields}
          formObject={formState.state.manufacturingProcess!}
          onChange={onChange}
          editing={true}
        />
      )}
    </div>
  )
}

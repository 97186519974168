/* -------------------------- Design imports start -------------------------- */
import { AuthLayout, EmptyLayout, MainLayout } from './components/layout/MainLayout'
import SignInPage from './pages/Auth/SignInPage'
import SignUpPage from './pages/Auth/SignUpPage'
import GuestSignInPage from './pages/Auth/GuestSignInPage'
import PasswordResetPage from './pages/Auth/ResetPasswordPage'
import UnauthRequestPage from './pages/Auth/UnauthRequestPage'
import ChangePasswordPage from './pages/Auth/ChangePasswordPage'
import CheckEmailPage from './pages/Auth/CheckEmailPage'
import VerifyPage from './pages/Auth/VerifyPage'
import LoadingSkeleton from './components/widgets/LoadingSkeleton'
import WrongRoutePage from './pages/WrongRoutePage'
import ContractDetailsPage from './features/Contract/Contract/ContractDetailsPage'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { Suspense, lazy, useEffect, useState } from 'react'
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import { Redirect, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { AuthenticatedRoute, PublicRoute } from './components/layout/Route'
import MUItheme from './utils/theme'
import Cookies from 'js-cookie'
import { ConfigProvider, theme } from 'antd'
import { JoyrideProvider } from './components/widgets/Joyride'
import { Role, User } from './utils/types'
import { NewPermissions } from './features/Roles/utils/types'
import { fetchUser, refreshAccessToken, rolesToPermissions } from './utils/functions'
import { CountContext, UserContext } from './utils/context'
import LogTool from './logger/logTools'
import WebsocketProvider from './utils/WebsocketProvider'
import { fetchGuestRequests } from './features/UnauthRequest/utils/functions'
import { GuestRequest } from './features/UnauthRequest/utils/types'
import { fetchOffers, fetchRequests } from './features/Request/utils/functions'
import { Offer, Request } from './features/Request/utils/types'
import { fetchBills, fetchContracts } from './features/Contract/utils/functions'
import { Bill, Contract } from './features/Contract/utils/types'
import SharepointGlobalStateProvider from './features/Sharepoint/SharepointGlobalStates'
import ChatSystemGlobalStateProvider from './features/ChatSystem/ChatSystemGlobalStateProvider'
import PermissionsPage from './pages/PermissionsPage'
/* ------------------------- Functional imports end ------------------------- */
//import PermissionsPage from './pages/PermissionsPage'

const maintenanceInfoPage = lazy(() => import('./pages/MaintenanceInfoPage'))
const newRequest = lazy(() => import('./features/Contract/Contract/ContractPage'))
const stockChangeDiagramPage = lazy(() => import('./features/Stock/StockChangeDiagramPage'))
const productionOrder = lazy(() => import('./pages/ProductionOrderPage'))
const processes = lazy(() => import('./features/Item/ProcessPage'))
const itemStock = lazy(() => import('./features/Stock/ItemStockPage'))
const stockPage = lazy(() => import('./pages/StockPage'))
const supplierDetailsPage = lazy(() => import('./features/Supplier/SupplierDetailsPage'))
const ordersPage = lazy(() => import('./pages/OrdersPage'))
const employeeDetailsPage = lazy(() => import('./features/employee/components/EmployeeDetailsPage'))
const fileSystemPage = lazy(() => import('./pages/FileSystemPage'))
const chatSystemPage = lazy(() => import('./pages/ChatSystemPage'))
const bOMListPage = lazy(() => import('./pages/BOMListPage'))
const bOMDetailPage = lazy(() => import('./pages/BOMDetailPage'))
const profilePage = lazy(() => import('./pages/ProfilePage'))
const itemsPage = lazy(() => import('./pages/itemsPage'))
const sharepointOverviewPage = lazy(() => import('./pages/SharepointOverviewPage'))
const mainLayoutWithSetUser = lazy(() => import('./pages/itemsPage'))
const customerPage = lazy(() => import('./pages/CustomerPage'))
const customerOrdersPage = lazy(() => import('./features/Customer/CustomerOrdersPage'))
const employeePage = lazy(() => import('./pages/EmployeePage'))
const organizationPage = lazy(() => import('./pages/OrganizationPage'))
const rolesPage = lazy(() => import('./pages/RolesPage'))
const rolesDetailsPage = lazy(() => import('./pages/RolesDetailsPage'))
//const PermissionsPage = lazy(() => import('./pages/PermissionsPage'))
const supplierPage = lazy(() => import('./pages/SupplierPage'))
const supportPage = lazy(() => import('./pages/SupportPage'))
const welcomePage = lazy(() => import('./pages/WelcomePage'))
const contractOverviewAdminPage = lazy(() => import('./pages/ContractOverviewAdminPage'))
const contractAdminPage = lazy(() => import('./pages/ContractAdminPage'))
const contactPage = lazy(() => import('./pages/ContactPage'))
// const chatSystemStaffPage = lazy(() => import('./pages/staff/ChatSystemStaffPage'))
const RequestDetailsPage = lazy(() => import('./features/Request/Request/RequestDetailsPage'))
const contractSupplierSelectPage = lazy(() => import('./pages/ContractSupplierSelectPage'))

const history = createBrowserHistory()

export default function Routes() {
  const log = new LogTool({context: "Router", enable: true, logLevel: "debug"})
  const systemMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [darkMode, setDarkMode] = useState<boolean>(systemMode)
  const [roles, setRoles] = useState<Role[]>([])
  const [userPermissions, setUserPermissions] = useState<NewPermissions>({})
  const [user, setUser] = useState<User | undefined>()
  const [accessToken, setAccessToken] = useState<string>(Cookies.get('access') || '')

  const [initialGuestRequests, setInitialGuestRequests] = useState<GuestRequest[]>([])
  const [initialRequests, setInitialRequests] = useState<Request[]>([])
  const [initialOffers, setInitialOffers] = useState<Offer[]>([])
  const [initialContracts, setInitialContracts] = useState<Contract[]>([])
  const [initialBills, setInitialBills] = useState<Bill[]>([])
  const [countTotals, setCountTotals] = useState<number>(0)
  const [tabValue, setTabValue] = useState<number>(0)
  /* const algorithmTheme = darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm */
  const muiTheme = MUItheme(darkMode)

  const searchParams = new URLSearchParams(window.location.search)
  const paramValue = searchParams.get('focus')
  log.debug("paramValue ->", paramValue)
  /* useEffect(() => {
    const mode = Cookies.get('darkMode')
    if (mode) {
      setDarkMode(mode === 'true')
    }
  }, []) */

  useEffect(() => {
    async function getNewToken() {
      const newToken = await refreshAccessToken()
      Cookies.set('access', newToken)
      setAccessToken(newToken)
    }

    if (!accessToken) {
      const refreshToken = Cookies.get('refresh')
      if (refreshToken) {
        getNewToken()
      } else {
        const isSignUpPage = window.location.pathname === '/signUp'
        const isPasswordResetPage = window.location.pathname === '/reset-password'
        const isVerifyPage = window.location.pathname.includes('/event/')
        const isGuestSignInPage = window.location.pathname.includes('guest-signIn')
        const isMaintenanceInfoPage = window.location.pathname.includes('maintenance')
        if (isSignUpPage) {
          history.push('/signUp')
        } else if (isPasswordResetPage) {
          history.push('/reset-password')
        } else if (isVerifyPage) {
        // } else if (isMaintenanceInfoPage) {   --  uncomment this line once the MaintenanceInfoPage is production ready
        } else if (isGuestSignInPage) {
          // do nothing: stay on the guest-signIn page and keep the provided token
        }else {
          // Standardmäßig zur SignIn-Seite weiterleiten
          history.push('/signIn')
        }
      }
    } else {
      fetchUser({
        onSuccess: (user: User) => {
          if (Array.isArray(user.roles)) {
            setRoles(user.roles)
            setUserPermissions(rolesToPermissions(user.roles))
          }
          setUser(user)
        },
        onError: (error: any) => {
          log.error("fetchUser ->", error)
        },
        parameter: ['expand=roles'],
      })
      countNewRequests()
    }
  }, [accessToken])

  /**
   * Counts all new requests, offers, contracts, bills and all of them together as totals.
   * Sets the states, so that the numbers can be displayed in the header and on the ContractOverviewAdminPage.
   * To get all new requests, offers, contracts, bills the function compares the time that the objects were created with the time of the last login of the user.
   * This function does not count the requests, offers, contracts, bills that were created while the user was logged in. For that purpose a WebSocket is needed.
   */
  async function countNewRequests() {
    fetchGuestRequests({
      onSuccess: (guestRequests: GuestRequest[]) => {
        const newGuestRequests = guestRequests.filter((guestRequest: GuestRequest) => guestRequest.read === false)
        setInitialGuestRequests(newGuestRequests)
      },
      onError: (error: any) => {
        log.error("countNewRequests ->", error)
      },
      parameter: ['update_read_by=false'],
    })
    fetchRequests({
      onSuccess: (requests: Request[]) => {
        const newRequests = requests.filter((request: Request) => request.read === false)
        setInitialRequests(newRequests)
      },
      onError: (error: any) => {
        log.error("countNewRequests ->", error)
      },
      parameter: ['update_read_by=false'],
    })
    fetchOffers({
      onSuccess: (offers: Offer[]) => {
        const newOffers = offers.filter((offer: Offer) => offer.read === false)
        setInitialOffers(newOffers)
      },
      onError: (error: any) => {
        log.error("countNewRequests ->", error)
      },
      parameter: ['update_read_by=false'],
    })
    fetchContracts({
      onSuccess: (contracts: Contract[]) => {
        const newContracts = contracts.filter((contract: Contract) => contract.read === false)
        setInitialContracts(newContracts)
      },
      onError: (error: any) => {
        log.error("countNewRequests ->", error)
      },
      parameter: ['update_read_by=false'],
    })
    fetchBills({
      onSuccess: (bills: Bill[]) => {
        const newBills = bills.filter((bill: Bill) => bill.read === false)
        setInitialBills(newBills)
      },
      onError: (error: any) => {
        log.error("countNewRequests ->", error)
      },
      parameter: ['update_read_by=false'],
    })
  }

  const MainLayoutWithSetUser = (props: any) => (
    <MainLayout {...props} setUser={setUser} darkMode={darkMode} setDarkMode={setDarkMode} />
  )

  log.debug(
    "User ->", user,
    ", accessToken ->", accessToken,
  )

  return (
    <ConfigProvider
      theme={
        {
          // algorithm: algorithmTheme,
        }
      }
    >
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Router history={history}>
          <Suspense fallback={<LoadingSkeleton pagetitle={''} />}>
            <JoyrideProvider>
              <UserContext.Provider
                value={{ accessToken, setAccessToken, user, setUser, roles, userPermissions }}
                >
                <CountContext.Provider
                  value={{
                    initialGuestRequests,
                    setInitialGuestRequests,
                    initialRequests,
                    setInitialRequests,
                    initialOffers,
                    setInitialOffers,
                    initialContracts,
                    setInitialContracts,
                    initialBills,
                    setInitialBills,
                    countTotals,
                    setCountTotals,
                    tabValue,
                    setTabValue,
                  }}
                >
                <ChatSystemGlobalStateProvider>
                <SharepointGlobalStateProvider>
                <WebsocketProvider>
                  <Switch>
                    <PublicRoute
                      path="/"
                      exact
                      layout={EmptyLayout}
                      component={() => (
                        <Redirect to={Cookies.get('refresh') ? '/items' : '/signIn'} />
                      )}
                    />
                    <AuthenticatedRoute
                      path="/stockChangeDiagram"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={stockChangeDiagramPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/newrequest"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={newRequest}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/productionOrder"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={productionOrder}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/processes"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={processes}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/items/sharepoints"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={sharepointOverviewPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/items/itemstock"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={itemStock}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/stock"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={stockPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/supplierdetails"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={supplierDetailsPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/orders"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={ordersPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/stock"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={stockPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/personnel/employee/list/details"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={employeeDetailsPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/files"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={fileSystemPage}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/chats"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={chatSystemPage}
                      // staffViewComponent={chatSystemStaffPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/items/bom/overview"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={bOMListPage}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/items/bom/detail"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={bOMDetailPage}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/profile"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={profilePage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/items"
                      component={itemsPage}
                      layout={MainLayoutWithSetUser}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/items/landingpage"
                      exact
                      layout={MainLayout}
                      component={mainLayoutWithSetUser}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/contacts"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={contactPage}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/contacts/customer/list"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={customerPage}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/contacts/customer/orders"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={customerOrdersPage}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/personnel/employees/list"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={employeePage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/personnel/organization"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={organizationPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/personnel/roles/list"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={rolesPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/personnel/roles/list/details"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={rolesDetailsPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    {
                      <AuthenticatedRoute
                        path="/personnel/permissions/list"
                        exact
                        layout={MainLayoutWithSetUser}
                        component={PermissionsPage}
                        user={user}
                      />
                    }
                    <AuthenticatedRoute
                      path="/contacts/supplier/list"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={supplierPage}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/support/contact"
                      exact
                      layout={MainLayoutWithSetUser}
                      component={supportPage}
                      user={user}
                    />
                    <AuthenticatedRoute
                      path="/contracts"
                      exact
                      showSettings
                      component={contractOverviewAdminPage}
                      layout={MainLayoutWithSetUser}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/contracts/details"
                      exact
                      showSettings
                      component={ContractDetailsPage}
                      layout={MainLayoutWithSetUser}
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/contracts/:id"
                      exact
                      component={contractAdminPage}
                      layout={MainLayoutWithSetUser}
                      showBack
                      title="pages.requestPage"
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/contracts/requests/:id"
                      exact
                      component={RequestDetailsPage}
                      layout={MainLayoutWithSetUser}
                      showBack
                      title="pages.requestPage"
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <AuthenticatedRoute
                      path="/contracts/contracts/:id"
                      exact
                      component={contractSupplierSelectPage}
                      layout={MainLayoutWithSetUser}
                      showBack
                      title="pages.requestPage"
                      user={user}
                      guestUserRedirect='/items'
                    />
                    <PublicRoute
                      path="/404"
                      exact
                      layout={EmptyLayout}
                      component={WrongRoutePage}
                      title="404"
                    />
                    <PublicRoute
                      path="/signIn"
                      exact
                      layout={AuthLayout}
                      component={() => <SignInPage paramValues={paramValue as string} />}
                    />
                    <PublicRoute
                      path="/signUp"
                      exact
                      layout={AuthLayout}
                      component={() => <SignUpPage setUser={setUser} />}
                    />
                    <PublicRoute
                      path="/event/:event"
                      exact
                      layout={AuthLayout}
                      component={VerifyPage}
                    />
                    <PublicRoute
                      path="/reset-password"
                      exact
                      layout={AuthLayout}
                      component={PasswordResetPage}
                    />
                    <PublicRoute
                      path="/reset-password/:token"
                      exact
                      layout={AuthLayout}
                      component={ChangePasswordPage}
                    />
                    <PublicRoute
                      path="/check-email"
                      exact
                      layout={AuthLayout}
                      component={CheckEmailPage}
                    />
                    <PublicRoute
                      path="/send-request"
                      exact
                      layout={AuthLayout}
                      component={UnauthRequestPage}
                    />
                    <PublicRoute
                      path="/guest-signIn/:token?"
                      exact
                      layout={AuthLayout}
                      component={GuestSignInPage}
                    />
                    <Redirect to="/404" />
                  </Switch>
              </WebsocketProvider>
              </SharepointGlobalStateProvider>
              </ChatSystemGlobalStateProvider>
                </CountContext.Provider>
              </UserContext.Provider>
            </JoyrideProvider>
          </Suspense>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  )
}

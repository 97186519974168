/* -------------------------- Design imports start -------------------------- */

import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import MultilevelDrawer from '../../components/layout/MultilevelDrawer'

/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useTranslation } from 'react-i18next'
import {
  createAddress,
  fetchAddresses,
  formInputToAPIAddressJSON,
  handleAPICallV1,
  missingRequiredFormFields,
} from '../../utils/functions'
import { useEffect, useRef, useState } from 'react'
import { Address, HTTPMethod, SelectOption } from '../../utils/types'
import LogTool from '../../logger/logTools'
import i18next from 'i18next'
import { toast } from 'react-toastify'
import { useUserContext } from '../../utils/context'

/* ------------------------- Functional imports end ------------------------- */

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  formState?: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
  onClose?: () => void
  onConfirm?: (input: any) => Promise<'SUCCESS' | 'ERROR'>
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function LanguageSelectionDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, formState, onClose, onConfirm } = props
  const { t } = useTranslation()
  const log = new LogTool({ context: 'LanguageDrawer', enable: true, logLevel: 'warn' })
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */

  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage)
      i18next.changeLanguage(currentLanguage)
    }
  }, [])

  useEffect(() => {
    // Überprüfe, ob die aktuelle Sprache 'en-US' ist, und ändere sie zu 'en'
    if(currentLanguage) {
    if (currentLanguage === 'en-US') {
      setCurrentLanguage('en')
    }
  }
  }, [currentLanguage])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  // some API functions like fetch, create, ...
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const handleConfirm = async () => {
    // change language on the frontend
    i18next.changeLanguage(currentLanguage)

    // update user preferences in the backend
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PATCH,
      'accounts/users/self/preferences/',
      undefined,
      {"language": currentLanguage},
    )

    if(response && !error) {
      // localStorage.setItem('language', currentLanguage)
      toast.success(t('common:feedback.success.successfullyChangedLanguage'))
    }
    if (onConfirm) {
      onConfirm(formState?.state)
    }
  }

  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        title={t('common:content.label.settings') as string}
        size="small"
        onClose={handleClose}
        confirmButtonProps={{
          text: t('common:interaction.button.save'),
          onConfirm: handleConfirm,
          disabled: false,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t('common:content.label.language')}</InputLabel>
              <Select
                id="languageSelect"
                value={currentLanguage}
                label="Language"
                onChange={langSelect => setCurrentLanguage(langSelect.target.value)}
              >
                <MenuItem id="english" value={'en'} onClick={() => setCurrentLanguage('en')}>
                  {t('common:content.label.english')}
                </MenuItem>
                <MenuItem id="german" value={'de'} onClick={() => setCurrentLanguage('de')}>
                  {t('common:content.label.german')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MultilevelDrawer>
    </>
  )
}

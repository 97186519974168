/* -------------------------- Design imports start -------------------------- */
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GetConstant from '../utils/constants'
import ProcessForm from './ProcessForm'
import { mapCategoryAndProcessToProcess } from '../utils/functions'
/* ------------------------- Functional imports end ------------------------- */

const manufacturingProcessOptions = [
  {
    category: 'ASSEMBLY',
    options: [
      { value: 'robot', label: 'Robot' },
      { value: 'manual', label: 'Manual' },
    ],
  },
  {
    category: 'JOINING',
    options: [
      { value: 'glueing', label: 'Glueing' },
      { value: 'soldering', label: 'Soldering' },
      { value: 'welding', label: 'Welding' },
    ],
  },
  {
    category: 'CNCMACHINING',
    options: [
      { value: 'milling', label: 'Milling' },
      { value: 'drilling', label: 'Drilling' },
      { value: 'turning', label: 'Turning' },
      { value: 'grinding', label: 'Grinding' },
    ],
  },
  {
    category: 'CASTING',
    options: [
      { value: 'dieCast', label: 'Diecast' },
      { value: 'Injection Moulding', label: 'Injection Moulding' },
      { value: 'Investment Casting', label: 'Investment Casting' },
      { value: 'Sand Casting', label: 'Sand Casting' },
    ],
  },
  {
    category: 'PCB',
    options: [
      {value: 'etching', label: 'Etching'}
    ]
  },
  {
    category: 'POSTPROCESSING',
    options: [
      { value: 'blasting', label: 'Blasting' },
      { value: 'brush', label: 'Brush' },
      { value: 'grinding', label: 'Grinding' },
      { value: 'polishing', label: 'Polishing' },
      { value: 'painting', label: 'Painting' },
      { value: 'anodizing', label: 'Anodizing' },
      { value: 'electroPlatedNickelPlating', label: 'Electro Plated Nickel Plating' },
      { value: 'electroPlatedChromePlating', label: 'Electro Plated Chrome Plating' },
      { value: 'laserEngraving', label: 'Laser Engraving' },
      { value: 'powderCoating', label: 'Powder Coating' },
      { value: 'customProcess', label: 'Custom Process' },
    ],
  },
  {
    category: 'SHEETMETAL',
    options: [
      { value: 'bending', label: 'Bending' },
      { value: 'laserCutting', label: 'Laser Cutting' },
      { value: 'punching', label: 'Punching' },
      { value: 'folding', label: 'Folding' },
      { value: 'tubeLaserCutting', label: 'Tube Laser Cutting' },
    ],
  },
  {
    category: 'PRINTING3D',
    options: [
      { value: '3DPrinting', label: '3D Printing' },
      { value: 'FDM', label: 'FDM' },
      { value: 'SLA', label: 'SLA' },
      { value: 'SLS', label: 'SLS' },
      { value: 'SLM', label: 'SLM' },
      { value: 'DLP', label: 'DLP' },
      { value: 'PBF', label: 'PBF' },
      { value: 'DMLS', label: 'DMLS' },
      { value: 'MJF', label: 'MJF' },
    ],
  },
  {
    category: 'STANDARDCOMPONENT',
    options: [{ value: 'standardComponent', label: 'Standard Component' }],
  },
  {
    category: 'RAWMATERIAL',
    options: [{ value: 'rawMaterial', label: 'Raw Material' }],
  },
  {
    category: 'QUALITYCONTROL',
    options: [{ value: 'qualityControl', label: 'Quality Control' }],
  },
  {
    category: 'CUSTOMPROCESS',
    options: [{ value: 'customProcess', label: 'Custom Process' }],
  },
] as any[]

type Props = {
  formState: {
    state: any | undefined
    setState: React.Dispatch<React.SetStateAction<any | undefined>>
  }
}

const emptyProcess: any = {
  assembly_manually: {
    time: '',
    remarks: '',
  },
  assembly_robot: {
    remarks: '',
  },
  joining_welding: {
    measurement: '',
    material: '',
    method: '',
    seam: '',
    custom_stick_count: '',
    remarks: '',
  },
  joining_soldering: {
    surface_area: '',
    material: '',
    method: '',
    seam: '',
    custom_thickness_soldering_gap: '',
    remarks: '',
  },
  joining_glueing: {
    layer_thickness: '',
    surface_area: '',
    material_1: '',
    material_2: '',
    application_method: '',
    custom_mechanical_load_capacity: '',
    custom_product_life_cycle: '',
    custom_temperature_resistance: '',
    custom_chemical_resistance: '',
    custom_weather_resistance: '',
    remarks: '',
  },
  cnc_milling: {
    surface_area: '',
    material: '',
    custom_tolerances: '',
    remarks: '',
  },
  cnc_drilling: {
    material: '',
    remarks: '',
  },
  cnc_turning: {
    surface_area: '',
    material: '',
    rotation_axis: '',
    custom_tolerances: '',
    remarks: '',
  },
  cnc_grinding: {
    material: '',
    remarks: '',
  },
  casting_die_cast: {
    post_treatment: '',
    material: '',
    custom_gating_line: '',
    custom_runner_position: '',
    custom_number_of_cavities: '',
    remarks: '',
  },
  casting_injection_moulding: {
    material: '',
    form_surface: '',
    mold: '',
    number_of_cavities: '',
    custom_dividing_line: '',
    custom_sprue_position: '',
    remarks: '',
  },
  casting_investment_casting: {
    material: '',
    remarks: '',
  },
  casting_sand_casting: {
    material: '',
    remarks: '',
  },
  postprocessing_blasting: {
    remarks: '',
  },
  postprocessing_brush: {
    remarks: '',
  },
  postprocessing_grinding: {
    remarks: '',
  },
  postprocessing_electroPlatedNickelPlating: {
    remarks: '',
  },
  postprocessing_electroPlatedChromePlating: {
    remarks: '',
  },
  postprocessing_laserEngraving: {
    remarks: '',
  },
  postprocessing_polishing: {
    remarks: '',
  },
  postprocessing_painting: {
    remarks: '',
    color: '',
  },
  postprocessing_anodizing: {
    remarks: '',
    color: '',
  },
  postprocessing_powderCoating: {
    remarks: '',
    color: '',
  },
  postprocessing_electroGalvanizing: {
    remarks: '',
  },
  sheetmetal_tubeLaserCutting: {
    material: '',
    remarks: '',
  },
  sheetmetal_punching: {
    material: '',
    remarks: '',
  },
  sheetmetal_bending: {
    material: '',
    remarks: '',
  },
  sheetmetal_folding: {
    material: '',
    remarks: '',
  },
  sheetmetal_laserCutting: {
    material: '',
    remarks: '',
  },
  printing3D_3DPrinting: {
    filling_degree: '',
    layer_height: '',
    material: '',
    orientation: '',
    custom_tolerances: '',
    remarks: '',
  },
  standardComponent_standardComponent: {
    remarks: '',
  },
  customProcess_customProcess: {
    remarks: '',
  },
  qualityControl_qualityControl: {
    remarks: '',
  },
  rawMaterial_rawMaterial: {
    remarks: '',
  },
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
/**
 * Lets you select a Manufacturingprocess.
 * After selection the connected requirements will be displayed
 */
export default function ProcessSelection(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { formState } = props
  const { t } = useTranslation(['item'])
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])
  /* ----------------------------- Flag states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if (formState.state.manufacturingProcess?.category) {
      const options: any[] = manufacturingProcessOptions
        .filter((option: any) => option.category === formState.state.manufacturingProcess?.category)
        .map((category: any) => category.options)[0]
      setOptions(options ? options : [])
    }
    if (formState.state.manufacturingProcess) {
      sessionStorage.setItem(
        'manufacturingProcess',
        JSON.stringify(formState.state.manufacturingProcess)
      )
    }
  }, [])
  /* ------------------------------- Effects end ------------------------------ */

  /* -------------------------- Callbacks functions start -------------------------- */

  const handleChangeCategory = (category: string) => {
    const manufacturingProcess = JSON.parse(
      sessionStorage.getItem('manufacturingProcess') as string
    )
    if (manufacturingProcess?.category === category) {
      formState.setState((prev: any) => {
        return { ...prev, manufacturingProcess: manufacturingProcess }
      })
    } else {
      formState.setState((prev: any) => {
        return { ...prev, manufacturingProcess: { category: category } }
      })
    }
    const options: any[] = manufacturingProcessOptions
      .filter((option: any) => option.category === category)
      .map((category: any) => category.options)[0]
    setOptions(options ? options : [])
    if (options && options.length > 0) {
      const mapping: string = mapCategoryAndProcessToProcess(category, options[0]?.value as string)
      formState.setState((prev: any) => {
        return {
          ...prev,
          manufacturingProcess: {
            ...prev.manufacturingProcess,
            process: options[0]?.value,
            ...emptyProcess[mapping],
          },
        }
      })
    }
  }

  const handleChangeManufacturingProcess = (event: React.ChangeEvent<{ value: unknown }>) => {
    const mapping: string = mapCategoryAndProcessToProcess(
      formState.state.manufacturingProcess?.category,
      event.target.value as string
    )
    formState.setState((prev: any) => {
      return {
        ...prev,
        manufacturingProcess: {
          category: prev.manufacturingProcess.category,
          process: event.target.value as string,
          ...emptyProcess[mapping],
        },
      }
    })
  }

  /* --------------------------- Callbacks functions end --------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const categoryList = GetConstant({ name: 'categoryList' }) as any[]

  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Grid container spacing={1} justifyContent="space-evenly">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl sx={{ mb: 2, mt: 2 }} fullWidth>
              <InputLabel id="category">{t('common:content.label.category')}</InputLabel>
              <Select
                id="categorySelect"
                onChange={(e: any) => {
                  handleChangeCategory(e.target.value)
                }}
                value={formState.state.manufacturingProcess?.category ?? ''}
                labelId="category"
                label={t('common:content.label.category')}
              >
                {categoryList &&
                  categoryList.map((category: any, index: number) => (
                    <MenuItem key={index} value={category.category}>
                      {t('item:content.label.selectCategory.' + category.category.toLowerCase())}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={1} justifyContent="space-evenly">
          {formState.state.manufacturingProcess?.category && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl fullWidth>
                <InputLabel id="manufacturingProcess">
                  {t('common:content.label.manufacturingProcess')}
                </InputLabel>
                <Select
                  id="manufacturingProcessSelect"
                  onChange={(e: any) => handleChangeManufacturingProcess(e)}
                  value={formState.state.manufacturingProcess?.process ?? ''}
                  labelId="manufacturingProcess"
                  label={t('common:content.label.manufacturingProcess')}
                >
                  {options &&
                    options.map((option: any, index: number) => (
                      <MenuItem key={index} value={option.value}>
                        {t('item:content.label.selectProcess.' + option.label.toLowerCase())}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ProcessForm formState={formState} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

/* ------------------------ Functional imports start ------------------------ */
import { APIChannelFromJSON, APIMessageFromJSON, APIAllInOneUserFromJSON } from "../../../generated-types"
import LogTool from "../../../logger/logTools"
import { buildFetchUrl, createUser, createUserPreferences, getPKfromSelf, handleAPICallV1, parseNestedObject, parseNestedObjectList } from "../../../utils/functions"
import { FetchKwargs, FetchKwargsSingle, HTTPMethod, User } from "../../../utils/types"
import { Author, Channel, Message, MessageDate } from "./types"
/* ------------------------ Functional imports end -------------------------- */

const log = new LogTool({context: 'ChatUtils', enable: true, logLevel: 'warn'})

/* -------------------------------------------------------------------------- */
/*                           Utility functions start                          */
/* -------------------------------------------------------------------------- */

export function parseMessageDate(dateString: string): MessageDate {
  const date = dateString.split(/-|T|:|\.|Z/)
  return {
    year: parseInt(date[0]),
    month: parseInt(date[1]),
    day: parseInt(date[2]),
    hour: parseInt(date[3]),
    minute: parseInt(date[4]),
    second: parseInt(date[5]),
    millisecond: parseInt(date[6]),
  }
}

// export function createChatUser(responseObject: any): ChatUser {
//   const apiChatUser: APIChatUser = APIChatUserFromJSON(responseObject)

//   return {
//     ...apiChatUser,
//     key: getPKfromSelf(apiChatUser.self)
//   }
// }

export function createAuthor(authorResponse: any): Author {
  const apiAuthor: any = APIAllInOneUserFromJSON(authorResponse)
  return {
    ...apiAuthor,
    key: getPKfromSelf(apiAuthor.self)
    // messages: apiAuthor.hasProperty("messages") ? parseNestedObjectList(apiAuthor.messages, createMessage) : null
  }
}

export function createMessage(messageResponse: any): Message {
  const apiMessage = APIMessageFromJSON(messageResponse)
  return {
    ...apiMessage,
    key: getPKfromSelf(apiMessage.self),
    author: (apiMessage.author === null || apiMessage.author === undefined)
      ? undefined
      : {...apiMessage.author, key: getPKfromSelf(apiMessage.author.self), preferences: createUserPreferences({})},
    channel: parseNestedObject(apiMessage.channel, createChannel),
    isNewMessage: Boolean(apiMessage.isNewMessage),
  }
}

export function createChannel(channelResponse: any): Channel {
  const apiChannel = APIChannelFromJSON(channelResponse)

  return {
    ...apiChannel,
    key: getPKfromSelf(apiChannel.self),
    creator: parseNestedObject<User>(apiChannel.creator, createUser),
    members: parseNestedObjectList<User>(apiChannel.members, createUser),
    messages: parseNestedObjectList(apiChannel.messages, createMessage),
    unreadMessagesCount: 0, // to calculate the unreadMessageCount we need to know who the logged in user is -> calculate this property in a component
    lastReadAt: new Date(apiChannel.lastReadAt),
    prevLastReadAt: new Date(apiChannel.lastReadAt),
    get newestMessage() {
      // this getter function will always return the last entry in the message property
      // of the channel that is not a system message. That is the newest message, as long
      // as the message property is sorted (which it always should be!)
      if(typeof this.messages !== 'string') {
        let reverseIndex = this.messages.length - 1
        while(reverseIndex >= 0) {
          if(this.messages[reverseIndex].author) {
            return this.messages[reverseIndex]
          } else {
            reverseIndex --;
          }
        }
      }
      return undefined
    }
  }
}

export function inputToAPIChannelJSON(input: any) {
  return {
    ...(input.name && {'name': input.name}),
    ...(input.description && {'description': input.description}),
    ...(input.members && {'members': input.members}),
  }
}

export function inputToAPIMessageJSON(input: string) {
  return {
    'body': input
  }
}

export function getUnreadMessageCount(channel: Channel, user: User) {
  if(typeof channel.messages !== 'string') {
    const lastReadAt = channel.lastReadAt.getTime()
    let unreadMessageCount = 0
    let reverseIndex = channel.messages.length - 1
    // loop through all messages from newest to oldest and count the unread messages.
    while(reverseIndex >= 0) {
      const message = channel.messages[reverseIndex]
      let authorKey: string | undefined = undefined
      if(typeof message.author !== 'undefined') {
        // message.author === undefined for system messages!
        authorKey = typeof message.author === 'string' ? getPKfromSelf(message.author) : message.author.key
      }
      if(authorKey === user.key || lastReadAt - channel.messages[reverseIndex].createdAt.getTime() > 0) {
        // Stop the loop once a message is found that the user as seen already or is the author of!
        break;
      }

      unreadMessageCount += 1
      reverseIndex -= 1
    }
    return unreadMessageCount
  }
  return 0
}

/* -------------------------------------------------------------------------- */
/*                             API functions start                            */
/* -------------------------------------------------------------------------- */

/**
 * Fetch messages data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchMessages({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'chat/messages/'
}: FetchKwargs<Message>): Promise<void>
{
  log.info("Begin fetching messages.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/chat\/messages\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch messages
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching messages for URL", fetchUrl, ", Response ->", response)
    const messages = response.results.map((messageResponse: any) => createMessage(messageResponse))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchMessages({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(messages)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching messages", error)
    onError(error)
  }
}
/**
 * Fetch channels data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchChannels({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'chat/channel/'
}: FetchKwargs<Channel>): Promise<void> {
  log.info("Begin fetching channels.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/chat\/channel\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch channels
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching channels for URL", fetchUrl, ", Response ->", response)
    const channels = response.results.map((channelResponse: any) => createChannel(channelResponse))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchChannels({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(channels)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching channels", error)
    onError(error)
  }
}
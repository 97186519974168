import Cookies from "js-cookie"
import { APIArticle, APIArticleFromJSON } from "../../../generated-types/models/APIArticle"
import LogTool from "../../../logger/logTools"
import { buildFetchUrl, createContact, createGuestUser, createRole, createUser, getPKfromSelf, handleAPICallV1, parseNestedObject, parseNestedObjectList } from "../../../utils/functions"
import { FetchKwargs, GuestUser, HTTPMethod, SelectOption } from "../../../utils/types"
import { Article, FeedbackForm, FeedbackSubmission, Sharepoint } from "./types"
import { Category, Property, Supplier } from "../../Supplier/utils/types"
import { createSupplier, formInputToAPISupplierJSON } from "../../Supplier/utils/functions"
import { APICategory, APIFeedbackForm, APIFeedbackFormFromJSON, APIFeedbackSubmission, APIFeedbackSubmissionFromJSON, APIProperty, APISharepoint, APISharepointFromJSON } from "../../../generated-types"
import { FormFieldType } from "../../Form"
import { createGuestRequest } from "../../UnauthRequest/utils/functions"
import { createBOMNode } from "../../Bom/utils/functions"
import { BOMNode } from "../../Bom/utils/types"
import { GuestRequest } from "../../UnauthRequest/utils/types"

const log = new LogTool({context: "itemUtils", enable: true, logLevel: 'warn'})


/* -------------------------------------------------------------------------- */
/*                           Utility functions start                          */
/* -------------------------------------------------------------------------- */

/**
 * Maps the Category and Process to the corresponding Process for the ProcessForm
 * @param category of Manufacturing Process
 * @param process for selected Category
 * @returns process name that is used for conditionally rendering the ProcessForm
 */
export const mapCategoryAndProcessToProcess = (category: string, process: string) => {
  const categoryAndProcess : string = category + "_" + process
  const processMapping : any = {
      "ASSEMBLY_manual": "assembly_manually",
      "ASSEMBLY_robot": "assembly_robot",
      "JOINING_welding": "joining_welding",
      "JOINING_soldering": "joining_soldering",
      "JOINING_glueing": "joining_glueing",
      "CNCMACHINING_milling": "cnc_milling",
      "CNCMACHINING_drilling": "cnc_drilling",
      "CNCMACHINING_turning": "cnc_turning",
      "CNCMACHINING_grinding": "cnc_grinding",
      "CASTING_dieCast": "casting_die_cast",
      "CASTING_Injection Moulding": "casting_injection_moulding",
      "CASTING_Sand Casting": "casting_sand_casting",
      "CASTING_Investment Casting": "casting_investment_casting",
      "PCB_etching": "pcb_etching",
      "POSTPROCESSING_blasting": "postprocessing_blasting",
      "POSTPROCESSING_brush": "postprocessing_brush",
      "POSTPROCESSING_grinding": "postprocessing_grinding",
      "POSTPROCESSING_polishing": "postprocessing_polishing",
      "POSTPROCESSING_painting": "postprocessing_painting",
      "POSTPROCESSING_anodizing": "postprocessing_anodizing",
      "POSTPROCESSING_electroPlatedNickelPlating": "postprocessing_electroPlatedNickelPlating",
      "POSTPROCESSING_electroPlatedChromePlating": "postprocessing_electroPlatedChromePlating",
      "POSTPROCESSING_laserEngraving": "postprocessing_laserEngraving",
      "POSTPROCESSING_powderCoating": "postprocessing_powderCoating",
      "POSTPROCESSING_electroGalvanizing": "postprocessing_electroGalvanizing",
      "POSTPROCESSING_customProcess": "postprocessing_customProcess",
      "SHEETMETAL_tubeLaserCutting": "sheetmetal_tubeLaserCutting",
      "SHEETMETAL_punching": "sheetmetal_punching",
      "SHEETMETAL_bending": "sheetmetal_bending",
      "SHEETMETAL_folding": "sheetmetal_folding",
      "SHEETMETAL_laserCutting": "sheetmetal_laserCutting",
      "PRINTING3D_3DPrinting": "printing3D_3DPrinting",
      "PRINTING3D_FDM": "printing3D_FDM",
      "PRINTING3D_SLA": "printing3D_SLA",
      "PRINTING3D_SLS": "printing3D_SLS",
      "PRINTING3D_SLM": "printing3D_SLM",
      "PRINTING3D_DLP": "printing3D_DLP",
      "PRINTING3D_PBF": "printing3D_PBF",
      "PRINTING3D_DMLS": "printing3D_DMLS",
      "PRINTING3D_MJF": "printing3D_MJF",
      "STANDARDCOMPONENT_standardComponent": "standardComponent_standardComponent",
      "RAWMATERIAL_rawMaterial": "rawMaterial_rawMaterial",
      "QUALITYCONTROL_qualityControl": "qualityControl_qualityControl",
      "CUSTOMPROCESS_customProcess": "customProcess_customProcess"
    }
  return processMapping[categoryAndProcess]
}

/**
 * Convert an Article-Server-Response into an Article object.
 * @param responseObject The APIArticle response Object.
 * @returns
 */
export function createArticle(responseObject: any): Article {
  /*const parseDuration = (duration: string): {weeks: string, days: string, hours: string} => {
    const durationParts = duration.split(/[\s:]/).map((part: string) => parseInt(part))
    let weeks: number = 0
    let days: number = 0
    let hours: number = 0

    if(duration.match(/\d{1,2} \d{1,2}:\d{1,2}:\d{1,2}/)) {
      // duration provides days and hours
      days = durationParts[0] % 7
      weeks = (durationParts[0] - days) / 7
      hours = durationParts[1]
    } else if(duration.match(/\d{1,2}:\d{1,2}:\d{1,2}/)) {
      // duration does not provide days -> days, weeks = 0
      hours = durationParts[0]
    }

    return {
      weeks: `${weeks}`,
      days: `${days}`,
      hours: `${hours}`
    }
  }*/


  const a: APIArticle = APIArticleFromJSON(responseObject)
  return {
    ...a,
    // ...((a.leadTime !== undefined && a.leadTime !== null) && {leadTime: parseDuration(a.leadTime)}),
    ...(a.articleType !== undefined && a.articleType !== null) && {type: a.articleType},
    key: getPKfromSelf(a.self),
    supplier: a.supplier ? createSupplier(a.supplier) : null,
    guestRequest: parseNestedObject(a.guestRequest, createGuestRequest),
  }
}

export function getArticleRepresentation(article: Article, maxLength: number = -1): string {
  let representation = ''
  if( article.name ) {
    representation += `${article.name} (${article.number})`
  }
  else {representation += `${article.number}`}

  if( maxLength > 0 && representation.length > maxLength) {
    // shorten the representation to the specified length
    representation = representation.slice(0, maxLength) + ' ...'
  }

  return representation
}

export function createCategory(responseObject: any): Category {
  const c: APICategory = responseObject
  return {
    ...c,
    key: getPKfromSelf(c.self),
  }
}

export function createProperty(responseObject: any): Property {
  const p: APIProperty = responseObject
  return {
    ...p,
    key: getPKfromSelf(p.self),
  }
}

export function createFeedbackForm(responseObject: any): FeedbackForm {
  const f: APIFeedbackForm = APIFeedbackFormFromJSON(responseObject)
  log.debug("feedbackFormInput ->", responseObject)
  return {
    ...f,
    formJson: f.formJson.map((formField: any) => {
      log.debug('rename properties of formField', formField)
      // we need to convert the 'form_field_type' property back into a 'type' property.
      // In the backend 'type' is a reserved keyword and therefore properties with that
      // name are renamed into non reserved names (e.g. 'form_field_type').
      formField = {
        ...formField,
        type: formField.formFieldType,
        // make sure that the formFields are rendered with the specified size at every screen-size
        sm: formField.xl,
        md: formField.xl,
        lg: formField.xl,
      }
      delete formField.formFieldType
      return formField
    }),
    key: getPKfromSelf(f.self)
  }
}

export function createFeedbackSubmission(responseObject: any): FeedbackSubmission {
  const s: APIFeedbackSubmission = APIFeedbackSubmissionFromJSON(responseObject)
  log.debug('APIFeedbackSubmission ->', s)
  return {
    ...s,
    key: getPKfromSelf(s.self),
    // sharepoint: parseNestedObject(s.sharepoint, createSharepoint),
    article: parseNestedObject(s.article, createArticle),
    // the submitted by is automatically parsed by the APIFeedbackSubmissionFromJSON function
    // -> we only need to convert it from APIGuestUser to GuestUser
    submittedBy: {
      ...s.submittedBy,
      key: getPKfromSelf(s.submittedBy.self),
      roles: parseNestedObjectList(s.submittedBy.roles, createRole),
      repFor: parseNestedObject(s.submittedBy.repFor, createContact),
    } as GuestUser,
  }
}

export function createSharepoint(responseObject: any): Sharepoint {
  const s: APISharepoint = APISharepointFromJSON(responseObject)
  return {
    ...s,
    key: getPKfromSelf(s.self),
    createdBy: parseNestedObject(s.createdBy, createUser),
    sharedArticles: parseNestedObjectList(s.sharedArticles, createArticle)  as unknown as Article[],
    sharedBOMs: parseNestedObjectList(s.sharedBOMs, createArticle)  as unknown as Article[],
    sharedGuestRequest: parseNestedObject(s.sharedGuestRequest, createGuestRequest)  as unknown as GuestRequest,
    sharedWith: parseNestedObjectList(s.sharedWith, createGuestUser) as unknown as GuestUser[],
    feedbackForm: parseNestedObject(s.feedbackForm, createFeedbackForm),
    feedbackSubmissions: parseNestedObjectList(s.feedbackSubmissions, createFeedbackSubmission) as unknown as FeedbackSubmission[],
    unreadFeedbackSubmissionCount: Number(s.unreadFeedbackSubmissionCount)
  }
}

/**
 * Convert an Article object to the JSON representation of the corresponding APIArticle object.
 * @param a Article to convert.
 * @returns
 */
export function inputToAPIArticleJSON(a: any): any {
  const parseSupplier = (supplier: any) => {
    if(typeof supplier === 'object' && Object.hasOwn(supplier, 'value')) {
      // supplier holds a form option object {value: string, label string}
      return {'supplier': supplier.value}
    }
    if(typeof supplier === 'string') {
      return {'supplier': supplier}
    }
    return {'supplier': null}
  }

  // TODO: figure out how to handle properties/categories
 // Handle categories
 const categories = Array.isArray(a?.category)
 ? a.category.map((category: any) => {
     if (category && category.value) {
       return category.value.self || category.value
     }
     return null;
   }).filter((category: any) => category !== null)
 : (a.category && a.category.value ? [a.category.value.self || a.category.value] : [])

// Handle properties
const properties = Array.isArray(a?.properties)
 ? a.properties.map((property: any) => {
      if (property && property.value) {
        return property.value.self || property.value
      }
      return null;
    }).filter((property: any) => property !== null)
  : (a.properties && a.properties.value ? [a.properties.value.self || a.properties.value] : [])

  return {
    'creation_date': new Date().toISOString(),
    ...(a.name && {'name': a.name}),
    ...(a.number && {'number': a.number}),
    ...(a.description && {'description': a.description}),
    ...(a.type && {'article_type': a.type}),
    ...(a.family && {'family': a.family}),
    ...(properties && {'properties': properties}),
    ...(categories && {'category': categories}),
    ...(a.weight && {'weight': { 'value': a.weight ,'unit': a.weightUnit}}),
    ...(a.length && {'length': { 'value': a.length ,'unit': a.lengthUnit}}),
    ...(a.width && {'width': { 'value': a.width ,'unit': a.widthUnit}}),
    ...(a.height && {'height': { 'value': a.height ,'unit': a.heightUnit}}),
    ...(a.volume && {'volume': { 'value': a.volume ,'unit': a.volumeUnit}}),
    ...(parseSupplier(a.supplier)),
    ...(a.brand && {'brand': a.brand}),
    ...(a.barcodeText && {'barcode_text': a.barcodeText}),
    ...(a.barcodeType && {'barcode_type': a.barcodeType}),
    ...(a.qualityClass && {'quality_class': a.qualityClass.value}),
    ...(a.countryOfOrigin && {'country_of_origin': a.countryOfOrigin.value}),
    ...(a.minimumStorage && {'minimum_storage': parseInt(a.minimumStorage)}),
    ...(a.minimumOrder && {'minimum_order': parseInt(a.minimumOrder)}),
    ...(a.batchSize && {'batch_size': a.batchSize}),
    ...(a.manufacturingProcess && {'manufacturing_process': a.manufacturingProcess}),
    ...((a.leadTime &&
          {'lead_time': {
            "months": !isNaN(parseInt(a.leadTime.months)) ? parseInt(a.leadTime.months) : 0,
            "weeks": !isNaN(parseInt(a.leadTime.weeks)) ? parseInt(a.leadTime.weeks) : 0,
            "days": !isNaN(parseInt(a.leadTime.days)) ? parseInt(a.leadTime.days) : 0}
          }
        )), //${(!isNaN(parseInt(a.leadTime.weeks)) ? parseInt(a.leadTime.weeks) * 7 : 0) + (!isNaN(parseInt(a.leadTime.days)) ? parseInt(a.leadTime.days) : 0)} ${!isNaN(parseInt(a.leadTime.hours)) ? parseInt(a.leadTime.hours) : 0}:00:00
    ...(a.versionComment && {'version_comment': a.versionComment}),
    ...(a.guestRequest && {'guest_request': a.guestRequest}),
  }
}

export function formInputToAPICategoryJSON(input: any) {
  return {
    ...(input.name && {'name': input.name}),
  }
}

export function formInputToAPIPropertyJSON(input: any) {
  return {
    ...(input.name && {'name': input.name}),
    ...(input.value && {'value': input.value}),
    ...(input.type && {'type': input.type}),
  }
}

export function inputToAPIFeedbackFormJSON(input: any) {
  return {
    ...(input.formTitle && {'form_title': input.formTitle}),
    // we need to rename the property 'type' of a form field to 'form_field_type'
    // because 'type' is a reserved keyword in the backend
    ...(input.formJson && {'form_json': input.formJson.map(
      (formField: any) => {
        formField = {
          ...formField,
          form_field_type: formField.type
        }
        delete formField.type
        return formField
      })}
    ),
  }
}

export function inputToAPIFeedbackSubmissionJSON(input: any, article: Article) {
  return {
    submission: input,
    article: article.self,
  }
}

export function inputToAPISharepointJSON(input: any) {
  return {
    ...(input.feedbackForm && {'feedback_form': input.feedbackForm}),
    ...(input.createdBy && {'created_by': input.createdBy}),
    ...(input.shareDuration && {'share_duration': input.shareDuration}),
    ...(input.manualPassword && {'manual_password': input.manualPassword}),
    ...(input.articles && {'articles': input.articles}),
    ...(input.isBomShare && {'is_bom_share': input.isBomShare}),
    ...(input.guestRequest && {'guest_request': input.guestRequest}),
    ...(input.automaticShares && {'automatic_shares': input.automaticShares.map(
        (automaticShare: any) => (
          {
            contact: automaticShare.contact,
            custom_invitation_message: automaticShare.customInvitationMessage ?? ""
          }
        ))}
      ),
    // manualShares is a list of strings that contain information about the
    // guestUser that should be created for manual sharing.
    ...(input.manualShares && {'manual_shares': input.manualShares}),
  }
}

export function createOption(responseObject: any, url: "supplier/suppliers/" | "items/categories/" | "items/properties/") : Supplier | Category | Property {
  switch(url) {
    case "supplier/suppliers/":
      return createSupplier(responseObject)
    case "items/categories/":
      return createCategory(responseObject)
    case "items/properties/":
      return createProperty(responseObject)
    default:
      throw new Error(`Error in createOption! Provided url ${url} does not match any of the valid urls supplier/suppliers/, items/categories/, or items/properties/.`)
  }
}

export function updateDataSource(articleToUpdate: Article, newArticle: Article) {
  if(articleToUpdate.name !== newArticle.name) {
    articleToUpdate.name = newArticle.name
  }
  if(articleToUpdate.number !== newArticle.number) {
    articleToUpdate.number = newArticle.number
  }
  if(articleToUpdate.description !== newArticle.description) {
    articleToUpdate.description = newArticle.description
  }
  if(articleToUpdate.barcodeType !== newArticle.barcodeType) {
    articleToUpdate.barcodeType = newArticle.barcodeType
  }
  if(articleToUpdate.barcodeText !== newArticle.barcodeText) {
    articleToUpdate.barcodeText = newArticle.barcodeText
  }
  if(articleToUpdate.brand !== newArticle.brand) {
    articleToUpdate.brand = newArticle.brand
  }
  if(articleToUpdate.brand !== newArticle.brand) {
    articleToUpdate.brand = newArticle.brand
  }
  if(articleToUpdate.weight !== newArticle.weight) {
    articleToUpdate.weight = newArticle.weight
  }
  if(articleToUpdate.length !== newArticle.length) {
    articleToUpdate.length = newArticle.length
  }
  if(articleToUpdate.width !== newArticle.width) {
    articleToUpdate.width = newArticle.width
  }
  if(articleToUpdate.height !== newArticle.height) {
    articleToUpdate.height = newArticle.height
  }
  if(articleToUpdate.volume !== newArticle.volume) {
    articleToUpdate.volume = newArticle.volume
  }
  if(articleToUpdate.countryOfOrigin !== newArticle.countryOfOrigin) {
    articleToUpdate.countryOfOrigin = newArticle.countryOfOrigin
  }
  if(articleToUpdate.batchSize !== newArticle.batchSize) {
    articleToUpdate.batchSize = newArticle.batchSize
  }
  if(articleToUpdate.manufacturingProcess !== newArticle.manufacturingProcess) {
    articleToUpdate.manufacturingProcess = newArticle.manufacturingProcess
  }
}

/**
 * Convert an Article-Object into a data-representation that can be used by the Form component.
 * @param article The article to convert.
 * @returns
 */
export function getFormObjectFromArticle(article: Article): Article & {
  barcodeType: number,
  type: string,
  family: string,
  properties: string,
  category: string,
  supplier: SelectOption,
}
{
  // typecast to enable access to expanded properties
  const a: any = article as Object
  return {
    ...a,
    barcodeType: a.barcodeType,
    type: a.type?.name,
    family: a.family?.name,
    properties: a.properties?.map((property: any) => ({value: property, label: property.name, disabled: false})),
    category: a.category?.map((category: any) => ({value: category, label: category.name, disabled: false})),
    supplier: a.supplier ? {value: a.supplier?.self, label: a.supplier?.name} : "",
    weight: a.weight?.value ?? "",
    length: a.length?.value ?? "",
    width: a.width?.value ?? "",
    height: a.height?.value ?? "",
    volume: a.volume?.value ?? "",
  }
}

export function cubic_symbol(unit: string): string {
  switch(unit) {
    case 'cubic_meter':
      return 'm³'
    case 'cubic_decimeter':
      return 'dm³'
    case 'cubic_centimeter':
      return 'cm³'
    case 'cubic_millimeter':
      return 'mm³'
    default:
      return 'm³'
  }
}



/* -------------------------------------------------------------------------- */
/*                             API functions start                            */
/* -------------------------------------------------------------------------- */


/**
 * Fetch article data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchArticles(
  kwargs: {
    onSuccess: (response: Article[], count?: any) => void,
    onFinal?: () => void,
    onError?: (error: any) => void,
    parameter?: string[],
    url?: string}
): Promise<void>
{
  // getting kwargs and setting defaults
  const {
    onSuccess,
    onFinal = () => null,
    onError = (error: any) => null,
    parameter = [],
    url = 'items/articles/'
  } = kwargs


  log.info("Begin fetching articles.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/items\/articles\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // rename 'type' in parameters to article_type. (Backend does not like using 'type' as a variable name)
  const renamedParameter = parameter.map((param: string) => {
    if(param.includes("type")) {
      return param.replaceAll("type", "article_type")
    }
    return param
  })

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, renamedParameter)

  // fetch articles
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching articles for URL", fetchUrl, ", Response ->", response)
    const articles = response.results?.map((articleResponse: any) => createArticle(articleResponse))
    const count = response.count

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchArticles({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(articles, count)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching articles", error)
    onError(error)
  }
}

export async function fetchArticle(
  kwargs: {
    onSuccess: (response: Article) => void,
    onError?: (error: any) => void,
    parameter?: string[],
    url?: string}
): Promise<void>
{
  // getting kwargs and setting defaults
  const {
    onSuccess,
    onError = (error: any) => null,
    parameter = [],
    url = 'items/articles/'
  } = kwargs

  log.info("Begin fetching article.")
  // check if the url leads to the correct API endpoint
  if(!url){
    log.error("No URL provided for fetching article!")
    return
  }
  if(!url.match(/items\/articles\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // rename 'type' in parameters to article_type. (Backend does not like using 'type' as a variable name)
  const renamedParameter = parameter.map((param: string) => {
    if(param.includes("type")) {
      return param.replaceAll("type", "article_type")
    }
    return param
  })

  const fetchUrl = buildFetchUrl(url, renamedParameter)

  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching article for URL", fetchUrl, ", Response ->", response)
    const article = createArticle(response)

    onSuccess(article)
  } else {
    log.error("Error fetching article", error)
    onError(error)
  }
}

/**
 * Create an Article-Database entry on the server.
 * @param a Article to post.
 * @param onSuccess Callback that is executed on receiving a response from the server.
 * @param onError Callback that is executed on receiving an error from the server.
 */
export async function postArticle(
  a: Article,
  onSuccess: () => void,
  onError: (error: any) => void
  ) {
  log.info("Begin posting article:", a.name ?? a.number)

  // convert Article-object to APIArticle JSON string
  const json = inputToAPIArticleJSON(a)
  // TODO: eslint disable no-unused-vars rule for next line
  const [error] = await handleAPICallV1(
    HTTPMethod.POST,
    'items/articles/',
    {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access')}`,
        "X-Revision": "true"
      },
    json
  )

  if(!error) {
    log.info("Success posting article:", a.name ?? a.number)
    onSuccess()
  } else {
    log.error("Error posting article:", a.name ?? a.number)
    onError(error)
  }
}

/**
 * Update an Article-Database entry on the server.
 * @param articleToUpdate The Article that needs to be updated.
 * @param updatedArticle The updated Article-Data.
 * @param onSuccess Callback that is executed on receiving a response from the server.
 * @param onError Callback that is executed on receiving an error from the server.
 */
export async function updateArticle(
  articleToUpdate: Article,
  updatedArticle: Article,
  onSuccess: (responseArticle: Article) => void,
  onError: (error: any) => void
) {
  log.info("Begin updating article:", articleToUpdate.name ?? articleToUpdate.number)

  // convert Article-object to APIArticle JSON string
  const json = inputToAPIArticleJSON(updatedArticle)
  // TODO: eslint disable no-unused-vars rule for next line
  const [response, error] = await handleAPICallV1(
    HTTPMethod.POST,
    articleToUpdate.self,
    undefined,
    json
  )

  if(!error && response) {
    log.info("Success updating article:", articleToUpdate.name ?? articleToUpdate.number)
    onSuccess(createArticle(response))
  } else {
    log.error("Error updating article:", articleToUpdate.name ?? articleToUpdate.number)
    onError(error)
  }
}

/**
 * Fetch article brands data from backend. Note that this fetches all pages of a paginated response sequentually.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchArticleBrands(kwargs: {
  onSuccess: (brands: string[]) => void,
  onFinal?: () => void
  onError?: (error: any) => void,
  url?: string,
}): Promise<void> {
  // get kwargs and set defaults
  const {
    onSuccess,
    onFinal = () => null,
    onError = (error: any) => null,
    url = 'items/brands/'
  } = kwargs


  log.info("Begin fetching article brands.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/items\/brands\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // fetch article brands
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    url
  )

  if(!error && response) {
    log.info("Success fetching article brands for URL", url, ", Response ->", response)
    const brands = response as string[]

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchArticleBrands({onSuccess: onSuccess, onError: onError, onFinal: onFinal, url: response.next})
    }

    // execute callbacks
    onSuccess(brands)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching article brands", error)
    onError(error)
  }
}

export async function fetchArticleCategories(kwargs: {
  onSuccess: (categories: Category[]) => void,
  onFinal?: () => void,
  onError?: (error: any) => void,
  url?: string,
}): Promise<void> {
  // get kwargs and set defaults
  const {
    onSuccess,
    onFinal = () => null,
    onError = (error: any) => null,
    url = 'items/categories/'
  } = kwargs

  log.info("Begin fetching article categories.")
  // check if the url leads to the correct API endpoint

  // fetch article categories

  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    url
  )

  if(!error && response) {
    log.info("Success fetching article categories for URL", url, ", Response ->", response)
    const categories = response.results.map((categoryResponse: any) => createCategory(categoryResponse))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchArticleCategories({onSuccess: onSuccess, onError: onError, onFinal: onFinal, url: response.next})
    }

    // execute callbacks
    onSuccess(categories)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching article categories", error)
    onError(error)
  }
}

export async function fetchArticleProperties(kwargs: {
  onSuccess: (properties: Property[]) => void,
  onFinal?: () => void,
  onError?: (error: any) => void,
  url?: string,
}): Promise<void> {
  // get kwargs and set defaults
  const {
    onSuccess,
    onFinal = () => null,
    onError = (error: any) => null,
    url = 'items/properties/'
  } = kwargs

  log.info("Begin fetching article properties.")
  // check if the url leads to the correct API endpoint

  // fetch article properties

  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    url
  )

  if(!error && response) {
    log.info("Success fetching article properties for URL", url, ", Response ->", response)
    const properties = response.results.map((propertyResponse: any) => createProperty(propertyResponse))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchArticleProperties({onSuccess: onSuccess, onError: onError, onFinal: onFinal, url: response.next})
    }

    // execute callbacks
    onSuccess(properties)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching article properties", error)
    onError(error)
  }
}

/**
 * Fetch feedback-form data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchFeedbackForms({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'items/feedback_forms/'
}: FetchKwargs<FeedbackForm>): Promise<void>
{
  log.info("Begin fetching feedback forms.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/items\/feedback_forms\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch suppliers
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching feedback forms for URL", fetchUrl, ", Response ->", response)
    const feedbackForms = response.results.map((formResponse: any) => createFeedbackForm(formResponse))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchFeedbackForms({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(feedbackForms)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching feedback forms", error)
    onError(error)
  }
}

/**
 * Fetch feedback submission data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchFeedbackSubmissions({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = ''
}: FetchKwargs<FeedbackSubmission>): Promise<void>
{
  log.info("Begin fetching feedback submissions.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/items\/feedback_forms\/\d+\/submissions/)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch suppliers
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching feedback submissions for URL", fetchUrl, ", Response ->", response)
    const feedbackSubmissions = response.results.map(
      (submissionResponse: any) => createFeedbackSubmission(submissionResponse)
    )

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchFeedbackSubmissions({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(feedbackSubmissions)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching feedback submissions", error)
    onError(error)
  }
}

/**
 * Fetch sharepoint data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchSharepoints({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'items/sharepoints/'
}: FetchKwargs<Sharepoint>): Promise<void>
{
  log.info("Begin fetching sharepoints.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/items\/sharepoints\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch suppliers
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )

  if(!error && response) {
    log.info("Success fetching sharepoints for URL", fetchUrl, ", Response ->", response)
    const sharepoints = response.results.map((sharepointResponse: any) => createSharepoint(sharepointResponse))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchSharepoints({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(sharepoints)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching sharepoints", error)
    onError(error)
  }
}

export const handleAddOption = async (
    url: "supplier/suppliers/" | "items/categories/" | "items/properties/",
    input: any,
    onSuccess: (response: Supplier | Category | Property) => void,
    onError: (error: any) => void
  ) => {
    let json;
    switch(url) {
      case "supplier/suppliers/":
        json = formInputToAPISupplierJSON(input)
        break
      case "items/categories/":
        json = formInputToAPICategoryJSON(input)
        break
      case "items/properties/":
        json = formInputToAPIPropertyJSON(input)
        break
      default:
        throw new Error(`Error in handleAddOption! Provided url ${url} does not match any of the valid urls supplier/suppliers/, items/categories/, or items/properties/.`)
    }

    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      url,
      undefined,
      json
    )

    if(!error && response) {
      log.info("Response:", response)
      const option = createOption(response, url)
      onSuccess(option)
    } else {
      log.error("Error:", error)
      onError(error)
    }
  }

/**
 * Fetch options for suggestions in a text field from backend. Note that this fetches all pages of a paginated response sequentually.
 * @returns Object with name of object as the label (e.g. {label: "name of object"})
 */
export const loadFieldOptionsArticle = async (url: string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching field', error)
    return []
  }
  if (response) {
    log.debug('response', response)
    const fieldData = response.results.map((field: any) => ({
      label: `${field.name}`,
    }))
    log.debug('fieldData', fieldData)
    if(response.next){
      const nextFieldData = await loadFieldOptionsArticle(response.next)
      fieldData.push(...nextFieldData)
    }
    log.debug('fieldData', fieldData)
    return fieldData
  }
  return []
}

/**
 * Fetch Objects that are options for a select field from backend. Note that this fetches all pages of a paginated response sequentually.
 * Only works if the object has a name field.
 * @param url The fetch base url.
 * @returns whole object as option (e.g. {value: objectData, label: objectData.name, disabled: false})
 */
export const loadFieldObjectsArticle = async (url: string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching field', error)
    return []
  }
  if (response) {
    log.debug('response', response)
    const fieldData = response.results.map((field: any) => ({
      value: field,
      label: `${field.name}`,
      disabled: false,
    }))
    log.debug('fieldData', fieldData)
    if(response.next){
      const nextFieldData = await loadFieldObjectsArticle(response.next)
      fieldData.push(...nextFieldData)
    }
    log.debug('fieldData', fieldData)
    return fieldData
  }
  return []
}
/* -------------------------- Design imports start -------------------------- */
import {
  TextField,
  Link,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Theme,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import Page from '../../components/layout/Page'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import useMobileService from '../../hooks/useMobileService'
import Cookies from 'js-cookie'
import LogTool from '../../logger/logTools'
import UnauthRequestForm from '../../features/UnauthRequest/UnauthRequestForm'
import { useUserContext } from '../../utils/context'
import { useWebSocket } from '../../WebSocketProvider'
import Footer from '../../components/layout/Footer'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Button from '../../components/Button'
import { fetchUser, handleAPICallV1 } from '../../utils/functions'
import { HTTPMethod, User } from '../../utils/types'
/* ------------------------- Functional imports end ------------------------- */

interface Props {
  paramValues: string
}
/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function SignInPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { paramValues } = props
  const log = new LogTool({ context: 'SignInPage', enable: true, logLevel: 'warn' })
  let currentLng: string = i18next.language
  if (currentLng?.match(/en/)) {
    // conver en-US to en
    currentLng = 'en'
  }
  const { setAccessToken } = useUserContext()
  const { setIsLoggedIn, setAccessTokenWS } = useWebSocket()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false)
  const [inviteSuccess, setInviteSuccess] = useState<boolean>(false)
  const [orgSignUpSuccess, setOrgSignUpSuccess] = useState<boolean>(false)
  const [passwordResetSuccess, setPasswordResetSuccess] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const [email, setEmail] = useState('')
  const [selectedForm, setSelectedForm] = useState<'signInForm' | 'unauthRequestForm'>(
    'unauthRequestForm'
  )
  const [password, setPassword] = useState('')
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const location = useLocation()
  const isMobile = useMobileService()
  const [parameter, setParameter] = useState('')
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Styles start ------------------------------ */
  const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
      primaryGrid: {
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 2,
        backgroundColor: '#fff',
        boxShadow: '0px 0px 10px 0px #0000001a',
      },
      secondaryGrid: {
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 1,
        backgroundColor: '#f1f1f1',
      },
    })
  )
  const classes = useStyles()
  /* ------------------------------- Styles end ------------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  const paramValue = paramValues

  //WIP:URLParams to setFocus on the selected form
  useEffect(() => {
    if (paramValue) {
      setParameter(paramValue)
      if (paramValue === 'unauthRequest') {
        setSelectedForm('unauthRequestForm')
        log.debug('selectedForm: ', selectedForm)
      } else if (paramValue === 'signIn') {
        setSelectedForm('signInForm')
        log.debug('selectedForm: ', selectedForm)
      }
    }
  }, [paramValue])

  useEffect(() => {
    if (location.state && location.state) {
      const state: any = location.state
      if (state.inviteSuccess) {
        setInviteSuccess(true)
      } else if (state.orgSignUpSuccess) {
        setOrgSignUpSuccess(true)
      } else if (state.passwordResetSuccess) {
        setPasswordResetSuccess(true)
      } else {
        setInviteSuccess(false)
        setOrgSignUpSuccess(false)
      }
    }
  }, [location])

  log.debug('inviteSuccess: ', inviteSuccess)
  log.debug('orgSignUpSuccess: ', orgSignUpSuccess)
  log.debug('passwordResetSuccess: ', passwordResetSuccess)

  useEffect(() => {
    setError(false)
  }, [password, email])

  useEffect(() => {
    if (emailInvalid) {
      setEmailInvalid(!emailIsValid(email))
    }
  }, [emailInvalid, email])

  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------ Callback functions start ------------------------ */

  const emailIsValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleSignIn = async (e: any) => {
    e.preventDefault()
    if (!emailIsValid(email)) {
      setEmailInvalid(true)
      return
    }
    if (emailIsValid(email) && password) {
      setLoading(true)
      const response = await fetch(process.env.REACT_APP_API_URL + 'auth/token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          charset: 'UTF-8',
        },
        body: JSON.stringify({ email, password }),
      })
      const data = await response.json()
      if (data.access && data.refresh) {
        Cookies.set('access', data.access)
        Cookies.set('refresh', data.refresh)
        setAccessToken(data.access)
        setAccessTokenWS(data.access)
        setIsLoggedIn(true)
        setLoading(false)

        // manage preferred language
        fetchUser({
          onSuccess: async (user: User) => {
            if(user.preferences.language && user.preferences.language != '') {
              if(user.preferences.language != currentLng) {
                i18next.changeLanguage(user.preferences.language)
              }
            } else {
              const [response, error] = await handleAPICallV1(
                HTTPMethod.PATCH,
                'v1/accounts/users/self/preferences/',
                undefined,
                {'language': currentLng}
              )
            }
          }
        })
      } else {
        setError(true)
        setIsLoggedIn(false)
        setLoading(false)
      }
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSignIn(e)
    }
  }

  const handleUnauthRequestFormClick = () => {
    setSelectedForm('unauthRequestForm')
  }

  const handleSignInFormClick = () => {
    setSelectedForm('signInForm')
  }

  /* ------------------------ Callback functions start ------------------------ */

  /* ------------------------- Render constants start ------------------------- */
  if (loading) return <CircularProgress />

  if (!loading && Cookies.get('access')) {
    history.push('/items')
  }
  /* -------------------------- Render constants end -------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */

  const renderSignInForm = () => {
    return (
      <Grid
        container
        spacing={2}
        width={isMobile ? '100%' : '60%'}
        justifyContent="center"
        alignItems="center"
        margin="auto"
      >
        <Grid item xs={12} md={12}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {inviteSuccess && (
              <>
                <Grid item xs={12}>
                  <Typography color="success" align="center">
                    {t('content.auth.signUpSuccess')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="success" align="center">
                    {t('content.auth.signUpSuccessText')}
                  </Typography>
                </Grid>
              </>
            )}
            {orgSignUpSuccess && (
              <>
                <Grid item xs={12}>
                  <Typography color="success" align="center">
                    {t('content.auth.orgSignUpSuccess')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="success" align="center">
                    {t('content.auth.orgSignUpSuccessText')}
                  </Typography>
                </Grid>
              </>
            )}
            {passwordResetSuccess && (
              <>
                <Grid item xs={12}>
                  <Typography color="success" align="center">
                    {t('content.auth.passwordResetSuccess')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="success" align="center">
                    {t('content.auth.passwordResetSuccessText')}
                  </Typography>
                </Grid>
              </>
            )}
            {/* <Grid item xs={12}>
              <Typography variant="h5" align="center">
                {t('content.auth.signIn')}
              </Typography>
            </Grid> */}
            <Grid item xs={12} height="80%">
              <Typography
                variant="h6"
                align="center"
                color={selectedForm === 'signInForm' || isMobile ? '' : 'gray'}
              >
                {t('content.auth.signInText1')} <b>{t('content.auth.signInText2')}</b>{' '}
                {t('content.auth.signInText3')}
              </Typography>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {error}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="email"
                label={t('content.auth.emailAddress')}
                variant="outlined"
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
                error={emailInvalid}
                helperText={emailInvalid && t('content.auth.invalidEmail')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="password">{t('common:content.auth.password')}</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        id="togglePasswordVisibilityButton"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t('common:content.auth.password')}
                />
              </FormControl>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {t('content.auth.signInError')}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                style={{ color: 'whitesmoke' }}
                fullWidth
                onClick={handleSignIn}
                onKeyDown={handleKeyDown}
              >
                {t('content.auth.signIn')}
              </Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Button
                variant="contained"
                color="info"
                style={{textTransform: 'none'}}
                fullWidth
                // onClick={() => history.push('/send-request')}
                href={"https://app.assemblean.com/send-request/?lng=" + currentLng}
              >
                {t('content.auth.sendRequest')}
              </Button>
            </Grid> */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Link href={`/reset-password?lng=${currentLng}`}>
                  {t('content.auth.forgotPassword')}
                </Link>
              </Box>
              <Box display="flex" alignItems="center">
                <Link href={`/signUp?lng=${currentLng}`}>
                  {t('content.auth.registerYourOrganization')}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  if (isMobile) {
    return (
      <Page title={''}>
        <Grid container padding={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <UnauthRequestForm selectedForm={selectedForm} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ position: 'relative', marginTop: '10px' }}
          >
            <span
              style={{
                fontSize: '1.5rem',
              }}
            >
              <b>{t('common:content.label.or')}</b>
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              marginTop: '10px',
            }}
          >
            {renderSignInForm()}
          </Grid>
        </Grid>
        <Footer />
      </Page>
    )
  }

  return (
    <Page title={''} withoutPadding>
      <Grid height={'98vh'} container>
        {/* <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          sx={{ position: 'relative', marginTop: '30px' }}
        >
          <Typography
            variant="h3"
            sx={{
              position: 'absolute',
              left: selectedForm ? (selectedForm === 'unauthRequestForm' ? '33%' : '67%') : '50%', // Ändern Sie diese Werte nach Bedarf
              transform: 'translate(-50%, -50%)',
            }}
            className={classes.grid}
          >
            <img
              src={logo}
              alt="assemblean logo"
              style={{
                width: '200px',
                height: '100px',
                display: 'block',
                margin: '0 auto',
                marginBottom: '-30px'
              }}
            />
          </Typography>
        </Grid> */}
        <Grid
          alignContent={'center'}
          item
          xs={6}
          sm={6}
          md={selectedForm ? (selectedForm === 'unauthRequestForm' ? 8 : 4) : 6}
          lg={selectedForm ? (selectedForm === 'unauthRequestForm' ? 8 : 4) : 6}
          xl={selectedForm ? (selectedForm === 'unauthRequestForm' ? 8 : 4) : 6}
          onClick={handleUnauthRequestFormClick}
          className={
            selectedForm === 'unauthRequestForm' ? classes.primaryGrid : classes.secondaryGrid
          }
        >
          <UnauthRequestForm selectedForm={selectedForm} />
        </Grid>
        <Grid
          alignContent={'center'}
          item
          xs={6}
          sm={6}
          md={selectedForm ? (selectedForm === 'signInForm' ? 8 : 4) : 6}
          lg={selectedForm ? (selectedForm === 'signInForm' ? 8 : 4) : 6}
          xl={selectedForm ? (selectedForm === 'signInForm' ? 8 : 4) : 6}
          onClick={handleSignInFormClick}
          className={selectedForm === 'signInForm' ? classes.primaryGrid : classes.secondaryGrid}
        >
          {renderSignInForm()}
        </Grid>
      </Grid>
      <Footer />
    </Page>
  )
}

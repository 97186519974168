/* eslint-disable import/prefer-default-export */

/* ------------------------ Functional imports start ------------------------ */
import { Readable } from 'stream'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import { Address, Contact, FetchKwargs, FetchKwargsSingle, FileInfo, GuestUser, HTTPMethod, OrganizationUser, RichContact, Role, SelectOption, User, UserPreferences } from './types'
import LogTool from '../logger/logTools'
import { FormFieldType } from '../features/Form'
import { APIAddressFromJSON, APIArticle, APIContactFromJSON, APICustomerOrder, APICustomerOrderFromJSON, APIFeedbackForm, APIGuestUserFromJSON, APIOrgUserFromJSON, APIProductionOrder, APIProductionOrderFromJSON, APIRole, APIRoleFromJSON, APISupplyOrder, APISupplyOrderFromJSON, APIAllInOneUser, APIAllInOneUserFromJSON, APIOrgUser, APIGuestUser, APIRichContactFromJSON, APIUserPreferences, APIUserPreferencesFromJSON, APIPatchedUserPreferencesRequestLanguage } from '../generated-types'
import { CustomerOrder, ProductionOrder, SupplyOrder } from '../features/ProductionOrder/utils/types'
import { NewPermissions } from '../features/Roles/utils/types'
import { createSharepoint } from '../features/Item/utils/functions'
import { createSupplier } from '../features/Supplier/utils/functions'
import { createOrganization } from '../features/Organization/utils/functions'
import { Supplier } from '../features/Supplier/utils/types'
import { createCustomer } from '../features/Customer/utils/functions'
import { Customer } from '../features/Customer/utils/types'
/* ------------------------- Functional imports end ------------------------- */

const log = new LogTool({context: "generalUtils", enable: false, logLevel: 'debug'})


/* -------------------------------------------------------------------------- */
/*                           Utility functions start                          */
/* -------------------------------------------------------------------------- */
/**
 * Gets roles from the user and builds the permissions object
 * @param roles from current user
 * @returns permissions object for the current user
 */
export const rolesToPermissions = (roles : Role[]) : NewPermissions => {
  const rolesPermissions = roles.map((role : Role) => role.permissions)
  const viewPermissions : any = rolesPermissions.reduce((acc : any, curr : any) => {
  return {
      ...acc,
      ...curr
  }
  }
  , {})
  const permissions : NewPermissions = {}
  Object.entries(viewPermissions).forEach(([key, value]) => {
  permissions[key] = []
  if (value && Object.keys(value).length > 0) {
      Object.keys(value).forEach(k => {
          permissions[key].push(k)
      })
  }
  })
  return permissions
}
/**
 * Check if the user has the permission to view a certain API endpoint
 * @param permissions the permissions of the user
 * @param apiPermissionType the API endpoint to check
 */
export function canView(permissions: NewPermissions, apiPermissionType: string) {
  return permissions[apiPermissionType]?.includes('view')
}

/**
 * Check if the user has the permission to edit a certain API endpoint
 * @param permissions the permissions of the user
 * @param apiPermissionType the API endpoint to check
 */
export function canEdit(permissions: NewPermissions, apiPermissionType: string) {
  return permissions[apiPermissionType]?.includes('edit')
}

/**
 * Check if the user has the permission to delete a certain API endpoint
 * @param permissions the permissions of the user
 * @param apiPermissionType the API endpoint to check
 */
export function canDelete(permissions: NewPermissions, apiPermissionType: string) {
  return permissions[apiPermissionType]?.includes('delete')
}

/**
 * Check if the user has the permission to create a certain API endpoint
 * @param permissions the permissions of the user
 * @param apiPermissionType the API endpoint to check
 */
export function canCreate(permissions: NewPermissions, apiPermissionType: string) {
  return permissions[apiPermissionType]?.includes('create')
}

/**
 * Check if the user is privileged by being an org admin, superuser or staff
 * @param user the user to check
 * @param type the type of privilege to check for
 */
export function isPrivileged(user: User | undefined, type: "STAFF" | "ORGADMIN" | "SUPERUSER" = "ORGADMIN") : boolean {
  switch(type) {
    case "STAFF":
      return user?.isStaff || false
    case "ORGADMIN":
      return user?.isOrgAdmin || false
    default:
      return user?.isStaff || user?.isOrgAdmin || user?.isSuperuser || false
  }
}
/**
 * Check the current navigation item is active
 * @param targetPath the target path
 * @param exact whether only the exact link should be equal
 * @param currentPath the current path of the brwoser
 * @returns true if the current path equals the targeted path
 */
export const isNavActive = (targetPath: string, exact: boolean, currentPath: string) =>
  exact ? currentPath === targetPath : currentPath.startsWith(targetPath)

/**
 * Filtering utility function with correct typing
 * Returns true if the value is not falsy e.g. not null or undefined
 * @param value to be checked
 * @returns boolean whether the value is not falsy
 */
export const notFalsy = <T>(value: T): value is NonNullable<typeof value> => !!value

/**
 * Filters all Items of queries which have already been deleted
 * @param list of items returned by query
 * */
/*export function notDeleted(list: any) {
  return list.filter((value: any) => value._deleted === null)
}*/

/**
 * Filters all Items that are owned by a specific user
 * @param owner of that item
 * @param list of items returned by query
 * */
export function ownedBy(owner: string, list: any) {
  return list.filter((value: any) => value.owner === owner)
}

/**
 * Formats given bytes into human-readable sizes
 * @param bytes number of bytes
 * @param decimals number of decimals displayed
 * */
export function formatBytes(bytes: number, decimals = 2) {
  if (!bytes) return ''
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * Downloads file from a given Blob Object and a filename
 * @param blob object to download
 * @param filename filename to save as
 * */
export function downloadBlob(
  blob: Blob | Readable | ReadableStream<any> | undefined,
  filename: string
) {
  const url = URL.createObjectURL(blob as Blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

/**
 * Download multiple blobs
 * @param blobs download objects
 */
/*
export function downloadBlobs(
  blobs: Blob[] | Readable[] | ReadableStream<any>[] | undefined /*, filenames: string[]*/ /*
) {
  blobs?.map((blob, index) => {
    const url: any = URL.createObjectURL(blob as Blob)
    fetch(url.download)
      .then(res => res.blob())
      .then(blob => {
        saveAs(blob, url.filename)
      })
  })
}
*/

/**
 * Group List of objects by a property
 * @param xs source object
 * @param prop property to group by
 * @returns object that is grouped by a property e.g. products grouped by owner
 * */
export function groupBy(xs: any, prop: string) {
  const grouped: any = {}
  for (let i = 0; i < xs.length; i++) {
    const p = xs[i][prop]
    if (!grouped[p]) {
      grouped[p] = []
    }
    grouped[p].push(xs[i])
  }
  return grouped
}

export function createPermissionsForTable(permissions: any[]) {
  const permissionForTable : any[] = permissions.map((p: any) => {
      return {
          ...p,
          type: p.name.split(":")[0],
          name: p.name.split(":")[1],
          description: p.description,
      }
  }).sort((a: any, b: any) => {
      // sort by type and then by name
      if(a.type < b.type) {
          return -1
      }
      if(a.type > b.type) {
          return 1
      }
      if(a.name < b.name) {
          return -1
      }
      if(a.name > b.name) {
          return 1
      }
      return 0
  })
  return permissionForTable
}

/**
 * Parsing function for nested objects within server responses. E.g. the supplier of an article.
 * @param nestedResponseObject The contents of a field that might contain a nested object.
 * @param createObject The function that should be used to parse the nested response object to a frontend object.
 * @returns The frontend object created by the createObject function or a backend hyperlink string pointing to the object.
 */
export function parseNestedObject<T>(nestedResponseObject: any, createObject: (nestedObject: any) => T): T | string {
  if(typeof nestedResponseObject === 'object' && nestedResponseObject !== null) {
    return createObject(nestedResponseObject)
  }
  // the response object does not contain an actual object. It probably contains the backend hyperlink pointing to that object
  return nestedResponseObject
}

export function parseNestedObjectList<T>(fieldResponse: any, createObject: (nestedObject: any) => T): T[] | string {
  if(Array.isArray(fieldResponse)) {
    if(fieldResponse.every(field => typeof field === 'object' && field !== null)) {
      // fieldResponse is a list of objects
      return fieldResponse.map(field => createObject(field))
    }
  }
  // fieldResponse does not contain objects
  // response likely contains url that leads to objects
  return fieldResponse
}

export function getStringPreview(string: string, previewLength: number = 50): string {
  let preview = string.trimStart().substring(0, previewLength).trimEnd()
  if( string.trim().length > previewLength) {
    // add ellipses to indicate that the message is longer than what the preview displays
    preview += ' ...'
  }
  return preview
}

export function createAddress(addressResponse: any): Address {
  const apiAddress = APIAddressFromJSON(addressResponse)
  return {
    ...apiAddress,
    ...addressResponse,
    key: getPKfromSelf(apiAddress.self)
  }
}

/**
 * Returns a representative string for an address.
 * @param address The address to generate the string representation for.
 * @param includeCity Wether or not the address representation should contain the addresses city information.
 * @param includeCountry Wether or not the address representation should contain the addresses country information.
 */
export function getAddressRepresentation(
  address: Address,
  includeCity: boolean = true,
  includeCountry: boolean = true,
): string {
  let repr = `${address.address1}`
  if(address.address2) {repr += `, ${address.address2}`}
  if(address.address3) {repr += `, ${address.address3}`}
  if(includeCity && address.city) {
    if(address.zipCode) {repr += `, ${address.zipCode} ${address.city}`}
    else {repr += `, ${address.city}`}
  }
  if(includeCountry && address.country) {repr += `, ${address.country}`}
  return repr
}

export function createContact(contactResponse: any): Contact {
  const apiContact = APIContactFromJSON(contactResponse)
  return {
    ...apiContact,
    key: getPKfromSelf(apiContact.self),
    addresses: parseNestedObjectList(apiContact.addresses, createAddress)
  }
}

export function createRichContact(richContactResponse: any): RichContact {
  const apiRichContact = APIRichContactFromJSON(richContactResponse)
  return {
    ...apiRichContact,
    key: getPKfromSelf(apiRichContact.self),
    addresses: parseNestedObjectList(apiRichContact.addresses, createAddress),
    suppliers: parseNestedObjectList(apiRichContact.suppliers, createSupplier) as unknown as Supplier[],
    customers: parseNestedObjectList(apiRichContact.customers, createCustomer) as unknown as Customer[],
  }
}

/**
 * Returns a representative string for a contact.
 * @param contact
 * @param contactFor The name of a supplier / customer that the contact is linked with.
 * @returns
 */
export function getContactRepresentation(contact: Contact, contactFor?: string): string {
  let representation = ''
  if( contact.firstName && contact.lastName) {
    representation += `${contact.firstName} ${contact.lastName}`
  } else {
    representation += `${contact.email}`
  }
  if(contactFor) {
    representation += ` (${contactFor})`
  }

  return representation
}

export function createRole(roleResponse: any): Role {
  const apiRole: APIRole = APIRoleFromJSON(roleResponse)

  return {
    ...apiRole,
    key: getPKfromSelf(apiRole.self)
  }
}

export function createUserPreferences(preferencesResponse: any): UserPreferences {
  return {
    language: preferencesResponse.language ?? '',
  }
}

export function createUser(userResponse: any): User {
  const apiAllInOneUser: APIAllInOneUser = APIAllInOneUserFromJSON(userResponse)
  log.debug('APIAllInOneUser ->', apiAllInOneUser)
  if( apiAllInOneUser.isGuestUser ) {
    return {
      ...apiAllInOneUser,
      key: getPKfromSelf(apiAllInOneUser.self),
      email: `guest-${apiAllInOneUser.id.substring(4, 9)}`,
      firstName: "Guest",
      lastName: "User",
      // sharepoint: parseNestedObject(apiAllInOneUser.sharepoint, createSharepoint),
      repFor: parseNestedObject(apiAllInOneUser.repFor, createContact),
      roles: parseNestedObjectList(apiAllInOneUser.roles, createRole),
      // objectPermissions: apiAllInOneUser.objectPermissions || {},
      preferences: createUserPreferences(apiAllInOneUser.preferences),
    }
  }
  return {
    ...apiAllInOneUser,
    key: getPKfromSelf(apiAllInOneUser.self),
    roles: parseNestedObjectList(apiAllInOneUser.roles, createRole),
    // objectPermissions: apiAllInOneUser.objectPermissions || {},
    preferences: createUserPreferences(apiAllInOneUser.preferences),
  }
}

export function createOrgUser(orgUserResponse: any): OrganizationUser {
  log.debug('OrgUserInput ->', orgUserResponse)
  return createUser(orgUserResponse) as OrganizationUser
}
export function createGuestUser(guestUserResponse: any): GuestUser {
  log.debug('GuestUserInput ->', guestUserResponse)
  return createUser(guestUserResponse) as GuestUser
}

export function getUserRepresentation(user: User): string {
  log.debug('userInput ->', user)
  if(user.isGuestUser) {
    return getGuestUserRepresentation(user as GuestUser)
  } else {
    return getOrgUserRepresentation(user as OrganizationUser)
  }
}

export function getOrgUserRepresentation(orgUser: OrganizationUser): string {
  let representation = ''
  if(orgUser.firstName && orgUser.lastName) {
    representation += `${orgUser.firstName} ${orgUser.lastName}`
  } else {
    representation += orgUser.email
  }
  return representation
}

export function getGuestUserRepresentation(guestUser: GuestUser): string {
  let representation = ''
  log.debug("get representation for ->", guestUser, ", repFor === Object ->", typeof guestUser.repFor === 'object')
  if(typeof guestUser.repFor === 'object') {
    // the guest user represents a contact
    representation += getContactRepresentation(guestUser.repFor)}
  else {representation += guestUser.comment}
  if(representation === '') {
    // The guest user neither represents a contact nor provides a comment that identifies its purpose.
    // This should never happen, but just in case we provide a fallback representation.
    representation += 'Unidentified Guest'
  }
  return representation
}

export function createFileInfo(fileResponse: any): FileInfo {
  return{
    // typescript File properties
    ...(fileResponse.lastModified && {lastModified: fileResponse.lastModified}),
    ...(fileResponse.webkitRelativePath && {webkitRelativePath: fileResponse.webkitRelativePath}),
    ...(fileResponse.size && {size: fileResponse.size}),
    ...(fileResponse.type && {type: fileResponse.type}),
    // general backend file properties
    ...(fileResponse.self && {self: fileResponse.self}),
    ...(fileResponse.document && {document: fileResponse.document}),
    ...(fileResponse.stored_at && {storedAt: fileResponse.stored_at}),
    ...(fileResponse.updated_at && {storedAt: fileResponse.updated_at}),
    // articleFile properties
    ...(fileResponse.article && {article: fileResponse.article}),
    // requestFile properties
    ...(fileResponse.request && {request: fileResponse.request}),
    // guestRequest properties
    ...(fileResponse.guest_request && {guestRequest: fileResponse.guest_request}),
  }
}

export function formInputToAPIAddressJSON(addressInput: any) {
  return {
    // if the input provides a certain property, then add it as a JSON key-value pair
    ...(addressInput.address1 && {'address_1': addressInput.address1}),
    ...(addressInput.address2 && {'address_2': addressInput.address2}),
    ...(addressInput.address3 && {'address_3': addressInput.address3}),
    ...(addressInput.zipCode && {'zip_code': addressInput.zipCode}),
    ...(addressInput.city && {'city': addressInput.city}),
    ...(addressInput.country && {'country': addressInput.country}),
    ...(addressInput.type && {'type': addressInput.type}),
    ...(addressInput.info && {'info': addressInput.info}),
  }
}

export function formInputToAPIContactJSON(contactInput: any) {
  log.debug("Contact input addresses ->", contactInput?.addresses)
  return {
    // if the input provides a certain property, then add it as a JSON key-value pair
    ...(contactInput.email && {'email': contactInput.email}),
    ...(contactInput.phone && {'phone': contactInput.phone}),
    ...(contactInput.mobile && {'mobile': contactInput.mobile}),
    ...(contactInput.firstName && {'first_name': contactInput.firstName}),
    ...(contactInput.lastName && {'last_name': contactInput.lastName}),
    ...(contactInput.language && {'language': contactInput.language}),
    ...(contactInput.comment && {'comment': contactInput.comment}),
    ...(contactInput.preferredContactMethod && {'preferred_contact_method': contactInput.preferredContactMethod}),
    ...(contactInput?.addresses && {addresses: contactInput.addresses}
    // {
    // 'addresses': ( // validate addresses data
    //   Array.isArray(contactInput?.addresses) // addresses need to be presented in an array
    //   && typeof contactInput?.addresses[0] === 'object'  // address data should be stored in an object
    //   && Object.keys(contactInput.addresses[0]).length !== 0) // address data should not be empty
    //   ? [formInputToAPIAddressJSON(contactInput?.addresses[0])]
    //   : [formInputToAPIAddressJSON(contactInput?.addresses)]}
    )
  }
}

/**
 * Delete obj from array in place.
 * @param array
 * @param obj
 * @param comparisionFunc
 */
export function deleteObjFromArray<T extends object>(
  array: T[],
  obj: T,
  comparisionFunc?: (arrObj: T) => boolean
) {
  if(!comparisionFunc) {
    if(Object.hasOwn(obj, 'key')) {
      comparisionFunc = (arrObj: T) => {
        //typecast to unknown as TS does not recognize that type T has property "key" even though the obj of type T has property "key"
        const looselyTypedArrObj: any = arrObj as any
        const looselyTypedObj: any = obj as any

        return looselyTypedArrObj.key === looselyTypedObj.key
      }
    } else {
      throw new Error(
        'The provided object does not have a property "key" and therefore the default'
        + 'comparison function does not work. Either make sure that the object type contains'
        + 'a property "key" or specify a custom comparision function!'
      )
    }
  }
  // get current index of the object that is to be deleted
  const i = array.findIndex(comparisionFunc)
  // delete obj in place
  if (i > -1) {
    array.splice(i, 1)
  }
}

/**
 * Remove the last character in a string if it is a comma.
 * @param str
 */
export function removeTrailingComma(str: string): string {
  // check if last character is a comma
  if(str.match(/,$/)) {
    return str.slice(0, str.length-1)
  } else {
    return str
  }
}

/**
 * Append additional parameters to a url used for an API-Request.
 * @param url The 'base-url' to append the parameters to.
 * @param params The parameters to append. These need to be provided in an object with one property: the parameter-name like 'expand'.
 * @returns
 */
export function appendURLParameter(url: string, paramKey: string, paramValues: string): string {
  let resultUrl: string = url

  // check if the url contains a ? already
  if(resultUrl.includes('?')) {
    // we are not appending the first parameter to the url -> use the & delimiter
    resultUrl += '&'
  } else {
    resultUrl += '?'
  }

  // append the parameter key and values
  resultUrl += paramKey + '=' + paramValues
  return resultUrl
}

export function buildFetchUrl(baseUrl: string, parameter: string[]): string {
  let fetchUrl = baseUrl
  for(const param of parameter) {
    // syntax check
    if(!param.match(/(\w+)=(\w+)(,\w+)*/)) throw new Error(`Syntax error in URL parameter ${param}`)

    // seperate the parameter key and values
    const [paramKey, paramValues] = param.split('=')

    if(baseUrl.includes(paramKey)) {
      // the parameter already exists in the baseUrl
      // -> do not append the parameter and continue with the next parameter
      continue
    } else {
      fetchUrl = appendURLParameter(fetchUrl, paramKey, paramValues)
    }
  }

  return fetchUrl
}

/**
 * Retrieve object identifier from url string.
 * @param self django self hyperlink url
 * @returns backend object id of the object that is fetchable through the self-url
 */
export function getPKfromSelf(self: string): string {
  if(!self) return ''
  // get all strings enclosed by slashes that contain at least one digit
  const pkMatches = self.match(/[^\/]*\d[^\/]*/g)
  if(pkMatches !== null) {
    // the last match is the object identifier we are looking for
    log.debug('matches ->', pkMatches)
    return pkMatches[pkMatches.length-1]
  }
  throw new Error(
    'The provided string does not contain a backend object identifier.'
  )
}
/**
 * basic check if a string or number could be a valid database primary key
 * @param pk value to verify as database primary key
 * @returns
 */
export function isValidPk(pk: string| number): boolean {
  if(typeof pk === 'string') {
    if(!Number.isNaN(pk) && Number(pk) > 0) {
      return true
    }
  } else if(typeof pk === 'number') {
    if(pk > 0) {
      return true
    }
  }
  return false
}

/**
 * Check if any required form field is not filled out yet.
 * @param formFields The form fields at hand.
 * @param formInput The state that holds the form field input data.
 * @returns true if any required form field is not filled out yet.
 */
export function missingRequiredFormFields(formFields: FormFieldType[], formInput: any): boolean {
  return formFields.some(
    (field: any) => {
      // current field is not required -> all good
      if(!field.required) return false
      // the field is required but the form state has no data (for that field)
      if(!formInput || (formInput && !formInput[field.key])) return true
      if(formInput[field.key]) {
        // the form state holds data for the current form field, but ...
        if(typeof formInput[field.key] === 'string') {
          // ... it might be just spaces
          return formInput[field.key].trim() === ''
        }
        // check for object data as provided by e.g. measurement fields
        if(typeof formInput[field.key] === 'object') {
          return Object.values(formInput[field.key]).some((value: any) => typeof value === 'undefined' || value.trim === '')
        }
      }
      return false
    }
  )
}

export function createProductionOrder(productionOrderResponse: any): ProductionOrder {
  const apiProductionOrder: APIProductionOrder = APIProductionOrderFromJSON(productionOrderResponse)
  return {
    ...apiProductionOrder,
    key: getPKfromSelf(apiProductionOrder.self),
  }
}

export function createSupplyOrder(supplyOrderResponse: any): SupplyOrder {
  const apiSupplyOrder: APISupplyOrder = APISupplyOrderFromJSON(supplyOrderResponse)
  return {
    ...apiSupplyOrder,
    key: getPKfromSelf(apiSupplyOrder.self),
    article: apiSupplyOrder.article,
    supplier: apiSupplyOrder.supplier,
  };
}

export function createCustomerOrder(customerOrderResponse: any): CustomerOrder  {
  const apiCustomerOrder: APICustomerOrder = APICustomerOrderFromJSON(customerOrderResponse)
  return {
    ...apiCustomerOrder,
    key: getPKfromSelf(apiCustomerOrder.self),
    article: apiCustomerOrder.article,
    customer: apiCustomerOrder.customer,
  }
}


/* -------------------------------------------------------------------------- */
/*                             API functions start                            */
/* -------------------------------------------------------------------------- */

export async function copyToClipboard(value: string | number): Promise<"SUCCESS" | "ERROR"> {
  try {
    await navigator.clipboard.writeText(String(value))
  } catch (err) {
    log.error("Error while copying to clipboard", err)
    return "ERROR"
  }
  log.info("Success copying value", value, " to clipboard.")
  return "SUCCESS"
}

/**
 * Refreshes the access token if it is expired using the refresh token
 */
export async function refreshAccessToken() {
  const refreshToken = Cookies.get('refresh')
  if (!refreshToken) return
  return fetch(process.env.REACT_APP_API_URL + 'auth/token/refresh/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh: refreshToken }),
  })
    .then(res => {
      if (!res.ok) {
        // redirect to login page
        /* window.location.href = '/login' */
        throw new Error(res.statusText)
      } else {
        return res.json()
      }
    })
    .then(res => {
      const decoded: any = jwt_decode(res.access)
      Cookies.set('access', res.access, { expires: new Date(decoded.exp * 1000) })
      return res.access
    })
}

/**
 * Handles API calls to the backend with the correct headers and refreshes access token
 * if it is expired
 * @param httpMethod HTTP method to use
 * @param url url to call
 * @param body body of the request
 * @param returnType type of the return value
 * @returns [response, error] of the API call
 */
export async function handleAPICallV1(
  httpMethod: HTTPMethod,
  url: string,
  headers?: any,
  body?: any,
  returnType: string = 'json',
  isFile: boolean = false,
): Promise<[any, any]> {
  const requestHeaders = headers
    ? headers
    : {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('access')}`,
      }
  const params = body
    ? {
        method: httpMethod,
        headers: requestHeaders,
        body: isFile ? body : JSON.stringify(body),
      }
    : {
        method: httpMethod,
        headers: requestHeaders,
      }
  try {
    const res = await fetch(
      url.includes('/v1/') ? url : process.env.REACT_APP_API_URL + url,
      params
    )
    if (!res.ok) {
      if (res.status === 401) {
        const newToken = await refreshAccessToken()
        if (!newToken) throw new Error('No new token')
        // update bearer token
        params.headers.Authorization = `Bearer ${newToken}`
        /* const newParams = body
          ? {
              method: httpMethod,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${newToken}`,
              },
              body: JSON.stringify(body),
            }
          : {
              method: httpMethod,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${newToken}`,
              },
            } */
        const newRes = await fetch(
          url.includes('/v1/') ? url : process.env.REACT_APP_API_URL + url,
          params
        )

        if (!newRes.ok) throw new Error(newRes.statusText)
        switch (returnType) {
          case 'json':
            return [await newRes.json(), null]
          case 'blob':
            return [await newRes.blob(), null]
          case 'text':
            return [await newRes.text(), null]
          default:
            return [await newRes.json(), null]
        }
      } else if(res.status === 403) {
        return [null, new Error('Forbidden')]
      }
      else {
        return [null, res]
      }
    }
    switch (returnType) {
      case 'json':
        return [await res.json(), null]
      case 'blob':
        return [await res.blob(), null]
      case 'text':
        return [await res.text(), null]
      default:
        return [await res.json(), null]
    }
  } catch (error) {
    log.error("Error in handleAPICallV1 -> ", error)
    return [null, error]
  }
}


/**
 * Fetch contact data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchContacts({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'contacts/contacts/'
}: FetchKwargs<Contact>): Promise<void>
{
  log.info("Begin fetching contacts.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/contacts\/contacts\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // build fetch url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch bom bases
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )
  if(!error && response) {
    log.info("Success fetching contacts for URL", fetchUrl, ", Response ->", response)
    const contacts = response.results.map(
      (contactResponse: any) => createContact(contactResponse))
    const count = response.count ?? 0

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchContacts({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(contacts, count)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching contacts", error)
    onError(error)
  }
}

/**
 * Fetch rich contact data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchRichContacts({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'contacts/rich_contacts/'
}: FetchKwargs<RichContact>): Promise<void>
{
  log.info("Begin fetching rich contacts.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/contacts\/rich_contacts\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // build fetch url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch bom bases
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )
  if(!error && response) {
    log.info("Success fetching rich contacts for URL", fetchUrl, ", Response ->", response)
    const contacts = response.results.map(
      (richContactResponse: any) => createRichContact(richContactResponse))

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchRichContacts({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
    }

    // execute callbacks
    onSuccess(contacts)
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching rich contacts", error)
    onError(error)
  }
}

/**
 * Fetch address data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchAddresses({
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
  parameter = [],
  url = 'contacts/addresses/'
}: FetchKwargs<Address>): Promise<void>
{
  let allAddresses: Address[] = [];

  const handleSuccess = (addresses: Address[]) => {
    allAddresses = [...allAddresses, ...addresses];
    onSuccess(allAddresses);
  }
  log.info("Begin fetching contacts.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/contacts\/addresses\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // build fetch url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch addresses
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )
  if (!error && response) {
    log.info("Success fetching addresses for URL", fetchUrl, ", Response ->", response);
    const addresses = response.results.map((addressResponse: any) => createAddress(addressResponse));
    handleSuccess(addresses); // Rufen Sie handleSuccess statt onSuccess direkt auf

    if (response.next) {
      log.debug("Fetching next page of addresses.");
      fetchAddresses({ onSuccess: handleSuccess, onError, onFinal, parameter, url: response.next });
    } else {
      onFinal();
    }
  } else {
    log.error("Error fetching addresses", error);
    onError(error);
  }
}

/**
 * Fetch contact data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onFinal Callback that is executed after calling onSuccess for the last time.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Array of URL parameter to append to the url.
 * @param url The fetch base url.
 * @returns
 */
export async function fetchFiles({
  url,
  onSuccess,
  onFinal = () => null,
  onError = (error: any) => null,
}: FetchKwargs<File> & {url: string}): Promise<void>
{
  log.info("Begin fetching files for URL", url)
  // check if the url leads to a correct API endpoint
  if(!url.match(/files\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // fetch files
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    url
  )
  if(!error && response) {
    log.info("Success fetching files for URL", url, ", Response ->", response)
    /* const files = response.results.map(
      (contactResponse: any) => createContact(contactResponse)) */

    // fetch all pages of a paginated server response
    if(response.next) {
      // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
      fetchFiles({onSuccess: onSuccess, onError: onError, onFinal: onFinal, url: response.next})
    }

    // execute callbacks
    onSuccess([response])
    if(!response.next) {
      // the are no more pages -> onSuccess was called for the last time
      onFinal()
    }
  } else {
    log.error("Error fetching files", error)
    onError(error)
  }
}

/**
 *
 * @param url
 * @param fileName
 */
export function awsPresignedUrlDownload(url: string, fileName: string = '', MIMEType: string = ''): HTMLAnchorElement {
  const downloadAnchor = document.createElement('a')
  downloadAnchor.setAttribute("href", url)
  downloadAnchor.setAttribute("type", MIMEType)
  downloadAnchor.setAttribute("download", fileName)
  document.body.appendChild(downloadAnchor)
  downloadAnchor.click()
  downloadAnchor.remove()
  return downloadAnchor
}

/**
 * Fetch Orders (Production, Supply, Customer) data from backend. Note that this fetches all pages of a paginated response sequentially.
 * @param onSuccess Callback that is executed every time when receiving a response from the server.
 * @param onError Callback that is executed every time when receiving an error from the server.
 * @param parameter Fetch parameter, e.g. expand, exclude, ... . Written as ['expand=supplier,article']
 * @param url The fetch url.
 * @returns
 */

export async function fetchOrders(
  kwargs: {
      onSuccess: (response: any, count: number) => void,
      onError?: (error: any) => void,
      parameter?: string[],
      url: string}
  ): Promise<void> {

      // getting kwargs and setting defaults
const {
  onSuccess,
  onError = (error: any) => null,
  parameter = [],
  url
} = kwargs

  // check if the url leads to the correct API endpoint
  log.info('Begin fetching productionOrders.')
  // check if the url leads to the correct API endpoint
  const fetchUrl = buildFetchUrl(url, parameter)
  log.debug(`Fetch url: ${fetchUrl}`)

  const[response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl)

  if(!error && response){
      log.info('Successfully fetched productionOrders.')
      if(url.includes('production_orders')){
          const productionOrders = response.results.map((productionOrder: any) => createProductionOrder(productionOrder))
          const count = response.count
          onSuccess(productionOrders,count)
      }
      else if(url.includes('supply_orders')){
          const supplyOrders = response.results.map((supplyOrder: any) => createSupplyOrder(supplyOrder))
          const count = response.count
          onSuccess(supplyOrders,count)
      }
      else if(url.includes('customer_orders')){
          const customerOrders = response.results.map((customerOrder: any) => createCustomerOrder(customerOrder))
          const count = response.count
          onSuccess(customerOrders,count)
      }
  if(response.next){
      fetchOrders({onSuccess, onError, parameter, url: response.next})
  }}
  else{
      log.error('Error fetching productionOrders', error)
      onError(error)
  }
}

/**
 * Post a single file to the specified url to create an association between the file and the object that the url belongs to.
 * @param url The url to upload the file to.
 * @param file The file to upload.
 * @returns
 */
export async function uploadFile({
  url,
  file,
  onSuccess = (response: FileInfo) => null,
  onError = (error: any) => null
}: {onSuccess?: (response: FileInfo) => void, onError?: (error: any) => void, url: string, file: File}) {
  log.debug("Begin sending file", file.name)

  // check if the url leads to an API endpoint that is intended to accept files
  if(!(url.match(/files\//) || url.match(/import/))) {
    log.error(`The provided URL ${url} does not lead to an API endpoint that is intended to accept file uploads!`)
    return
  }

  // create FormData object to make file ready for upload
  let fileFormData: FormData = new FormData()
  fileFormData.append('document', file)

  // try to upload the file
  const [response, error] = await handleAPICallV1(
    HTTPMethod.POST,
    url,
    {
      Accept: '*/*',
      Authorization: `Bearer ${Cookies.get('access')}`,
    },
    fileFormData,
    "text", // workaround to prevent json parsing on the response
    true
  )

  // handle the server response
  if(!error) {
    log.info("Success uploading file", response)
    onSuccess(createFileInfo(response))
  } else {
    log.error("Error uploading file", error)
    onError(error)
  }
}

/**
 * Post a single file to the specified url to create an association between the file and the object that the url belongs to.
 * @param url The url to upload the file to.
 * @param file The file to upload.
 * @returns
 */
export async function deleteFile({
  url,
  onSuccess = () => null,
  onError = (error: any) => null
}: {onSuccess?: () => void, onError?: (error: any) => void, url: string}) {
  log.debug("Begin deleting file")

  // check if the url leads to an API endpoint that is intended to accept files
  if(!url.match(/\/files\//)) {
    log.error(`The provided URL ${url} does not lead to an API endpoint that holds any files!`)
    return
  }

  // try to delete the file
  const [response, error] = await handleAPICallV1(
    HTTPMethod.DELETE,
    url,
    undefined,
    undefined,
    'text'
  )

  // handle the server response
  if(!error) {
    log.info("Success deleting file", response)
    onSuccess()
  } else {
    log.error("Error deleting file", error)
    onError(error)
  }
}

export async function fetchUser({
  onSuccess,
  onError = (error: any) => null,
  parameter = [],
  url = 'accounts/users/self/'
}: FetchKwargsSingle<User>): Promise<void>
{
  log.info("Begin fetching user.")
  // check if the url leads to the correct API endpoint
  if(!url.match(/accounts\/users\/self\//)) {
    log.error(`The provided URL ${url} does not lead to the correct API endpoint!`)
    return
  }

  // append the parameter to the url
  const fetchUrl = buildFetchUrl(url, parameter)

  // fetch user
  const [response, error] = await handleAPICallV1(
    HTTPMethod.GET,
    fetchUrl
  )
  if(!error && response) {
    log.info("Success fetching user for URL", fetchUrl, ", Response ->", response)
    const user = createUser(response)

    onSuccess(user)
  } else {
    log.error("Error fetching user", error)
    onError(error)
  }
}

export const loadArticleData = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'items/articles/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching articles', error)
    return []
  }
  if (response) {
    const articleData = response.results.map((article: APIArticle) => ({
      value: article.self,
      label: `${article.name} (${article.number})`,
    }))
    log.debug('articleData', articleData)
    return articleData
  }
  return []
}


export const loadAllArticleData = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'items/articles/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching articles', error)
    return []
  }
  if (response) {
    const articleData = response.results.map((article: APIArticle) => ({
      value: article.self,
      label: `${article.name} (${article.number})`,
    }))
    if(response.next){
      const nextArticleData = await loadArticleData(response.next)
      articleData.push(...nextArticleData)
    }
    log.debug('articleData', articleData)
    return articleData
  }
  return []
}
export const loadCustomerOptions = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'customer/customers/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching customers', error)
    return []
  }
  if (response) {
    const customerData = response.results.map((customer: any) => ({
      value: customer.self,
      label: `${customer.name}`,
    }))
    log.debug('customerData', customerData)
    return customerData
  }
  return []
}

export const loadFeedbackFormData = async (url?: string) : Promise<SelectOption[]> => {
  if(!url){
    url = 'items/feedback_forms/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching articles', error)
    return []
  }
  if (response) {
    const feedbackFormData = response.results.map((form: any) => ({
      value: form.self,
      label: form.form_title,
    }))
    return feedbackFormData
  }
  return []
}

export const loadAllCustomer = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'customer/customers/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching customers', error)
    return []
  }
  if (response) {
    const customerData = response.results.map((customer: any) => ({
      value: customer.self,
      label: `${customer.name}`,
    }))
    if(response.next){
      const nextCustomerData = await loadCustomerOptions(response.next)
      customerData.push(...nextCustomerData)
    }
    log.debug('customerData', customerData)
    return customerData
  }
  return []
}

export const loadSupplierData = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean}[]> => {
  if(!url){
    url = 'supplier/suppliers/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching suppliers', error)
    return []
  }
  if (response) {
    const supplierData = response.results.map((supplier: any) => ({
      value: supplier.self,
      label: `${supplier.name}`,
    }))
    log.debug('supplierData', supplierData)
    return supplierData
  }
  return []
}

export const loadAllSupplierData = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean}[]> => {
  if(!url){
    url = 'supplier/suppliers/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching suppliers', error)
    return []
  }
  if (response) {
    const supplierData = response.results.map((supplier: any) => ({
      value: supplier.self,
      label: `${supplier.name}`,
    }))
    if(response.next){
      const nextSupplierData = await loadSupplierData(response.next)
      supplierData.push(...nextSupplierData)
    }
    log.debug('supplierData', supplierData)
    return supplierData
  }
  return []
}

export const loadAddressOptions = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'contacts/addresses/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching addresses', error)
    return []
  }
  if (response) {
    const addressData = response.results.map((address: any) => ({
      value: address.self,
      label: `${address.address_1}, ${address.zip_code},  ${address.city}`,
    }))
    if(response.next){
      const nextAddressData = await loadAddressOptions(response.next)
      addressData.push(...nextAddressData)
    }
    log.debug('addressData', addressData)
    return addressData
  }
  return []
}

export const loadContactOptions = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'contacts/contacts/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching contacts', error)
    return []
  }
  if (response) {
    const contactData = response.results.map((contact: any) => ({
      value: contact.self,
      label: `${contact.first_name} ${contact.last_name}`,
    }))

    log.debug('contactData', contactData)
    return contactData
  }
  return []
}


export const loadAllContactOptions = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'contacts/contacts/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching contacts', error)
    return []
  }
  if (response) {
    const contactData = response.results.map((contact: any) => ({
      value: contact.self,
      label: contact.first_name && contact.last_name ? `${contact.first_name} ${contact.last_name}` : contact.email,
    }))
    if(response.next ){
      const nextContactData = await loadContactOptions(response.next)
      contactData.push(...nextContactData)
    }
    log.debug('contactData', contactData)
    return contactData
  }
  return []
}


export const loadStockOptions = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'logistics/storages/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching stocks', error)
    return []
  }
  if (response) {
    const stockData = response.results.map((stock: any) => ({
      value: stock.self,
      label: `${stock.name}`,
    }))
    if(response.next){
      const nextStockData = await loadStockOptions(response.next)
      stockData.push(...nextStockData)
    }
    log.debug('stockData', stockData)
    return stockData
  }
  return []
}

export const loadStoragePositionOptions = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'logistics/storage_positions/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching storage positions', error)
    return []
  }
  if (response) {
    const storagePositionData = response.results.map((storagePosition: any) => ({
      value: storagePosition.self,
      label: `${storagePosition.name}`,
    }))
    if(response.next){
      const nextStoragePositionData = await loadStoragePositionOptions(response.next)
      storagePositionData.push(...nextStoragePositionData)
    }
    log.debug('storagePositionData', storagePositionData)
    return storagePositionData
  }
  return []
}

export const loadBrandOptions = async (url?:string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'items/brands/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching brands', error)
    return []
  }
  if (response) {
    log.debug('response', response)
    const brandData = response.map((brand: any) => ({
      label: `${brand}`,
    }))
    log.debug('brandData', brandData)
    if(response.next){
      const nextBrandData = await loadBrandOptions(response.next)
      brandData.push(...nextBrandData)
    }
    log.debug('brandData', brandData)
    return brandData
  }
  return []
}

export const loadCountries = async () : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all?fields=name")
    if(!response.ok) {
      log.error('Error fetching countries', response)
      return []
    }
    const countries = await response.json()
    return countries.map((country: any) => ({
      value: country.name.common,
      label: country.name.common,
    })).sort((a: any, b: any) => a.label.localeCompare(b.label))
  } catch(error) {
    log.error('Error fetching countries', error)
    return []
  }
}

export const loadAllStaffUsers = async (url?: string) : Promise<{ value: string; label?: string; disabled?: boolean }[]> => {
  if(!url){
    url = 'accounts/users/'
  }
  const [response, error] = await handleAPICallV1(HTTPMethod.GET, url, undefined, undefined)
  if (error) {
    log.error('Error fetching contacts', error)
    return []
  }
  if (response) {
      const userData = response.results
        .filter((user: any) => user.is_staff && !['chat@test.assemblean.com', 'admin@assemblean.com', 'chat@assemblean.com'].includes(user.email))
        .map((user: any) => ({
          value: user,
          label: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email,
        }));
      if (response.next) {
        const nextUserData = await loadAllStaffUsers(response.next);
        userData.push(...nextUserData);
      }
      log.debug('userData', userData);
      return userData;
    }
  return []
}